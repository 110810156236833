import React from 'react'
import '../../../assets/scss/HealthcareProfessionals/Form.scss'
import trashIcon from '../../../assets/svg/trash.svg'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Select from "react-select"
import ResetButton from "../Styled/ResetButton"
import {Controller, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import isEmailValid from "../../../util/isEmailValid"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import colourStyles from "../../../constants/colourStyles"
import PrimaryButton from "../Styled/PrimaryButton"


const Form = props => {

    const {register, handleSubmit, setValue, watch, formState: {errors}, control} = useForm({mode: 'onSubmit'})

    const onSubmit = data => {
        data.id = props.id
        data.account_id = data.account_id.value

        props.handleUpdate(data)
    }

    const first_name = watch("first_name", props.first_name)
    const last_name = watch("last_name", props.last_name)
    const email = watch("email", props.email)
    const title = watch("title", props.title)
    const hcp_type = watch("hcp_type", props.hcp_type)
    const password = watch("password", props.password)

    return (
        <form
            className="edit-healthcare-professional"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault()
            }}
        >

            <div className="account_id">
                <Label
                    data-tooltip-id={`update-hcp-tooltip-${props.id}`}
                    data-tooltip-content="The Account ID for the item">
                    Account ID
                </Label>
                <Controller
                    render={({
                                 field: {onChange, value, name, ref},
                             }) => (
                        <Select
                            value={value}
                            name={name}
                            options={props.options}
                            onChange={onChange}
                            className="react-select-field"
                            placeholder="Please select an account..."
                            styles={colourStyles}
                            ref={ref}
                        />
                    )}
                    defaultValue={props.options.find(account => account.value === props.account_id)}
                    control={control}
                    rules={{required: true}}
                    name="account_id"
                />
                {errors.account_id && <InvalidFeedback>The `Account ID` field is required</InvalidFeedback>}
            </div>

            <div className="hcp_id">
                <Label
                    data-tooltip-id={`update-hcp-tooltip-${props.id}`}
                    data-tooltip-content="Healthcare Professional ID">
                    Healthcare Professional ID
                </Label>
                <InputField
                    isValid={!!errors.hcp_id}
                    type="text"
                    placeholder="Please enter a healthcare professional ID..."
                    defaultValue={props.hcp_id}
                    {...register(
                        'hcp_id',
                        {
                            required: true,
                            validate: {
                                lowerCase: value => value.toLowerCase() === value,
                                noSpaces: value => value.replace(" ", "") === value,
                                longerThanThreeLetters: value => value.length > 2
                            }
                        })
                    }
                    readOnly
                />
            </div>

            <div className="password">
                <Label
                    data-tooltip-id={`update-hcp-tooltip-${props.id}`}
                    data-tooltip-content="Password for HCP ID">
                    Password
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.password}
                        type="text"
                        placeholder="Please enter a password..."
                        defaultValue={props.password}
                        {...register(
                            'password',
                            {
                                required: true,
                                validate: {
                                    lowerCase: value => value.toLowerCase() === value,
                                    noSpaces: value => value.replace(" ", "") === value,
                                    longerThanThreeLetters: value => value.length > 2
                                }
                            })
                        }
                    />
                    {password.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('password', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.password && errors.password.type === 'required' &&
                    <InvalidFeedback>The `Password` field is required</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'lowerCase' &&
                    <InvalidFeedback>The password needs to be in lowercase</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'noSpaces' &&
                    <InvalidFeedback>The password cannot have spaces</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'longerThanThreeLetters' &&
                    <InvalidFeedback>The password must be at least 3 characters</InvalidFeedback>
                }
            </div>

            <div className="hcp_type">
                <Label
                    data-tooltip-id={`update-hcp-tooltip-${props.id}`}
                    data-tooltip-content="Type for the Healthcare Professional">
                    Type
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        type="text"
                        isValid={!!errors.hcp_type}
                        placeholder="Please enter a type..."
                        defaultValue={props.hcp_type}
                        {...register(
                            'hcp_type',
                            {
                                required: true
                            })
                        }
                    />
                    {hcp_type.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('hcp_type', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.hcp_type && <InvalidFeedback>The `Type` field is required</InvalidFeedback>}
            </div>

            <div className="title">
                <Label
                    data-tooltip-id={`update-hcp-tooltip-${props.id}`}
                    data-tooltip-content="Title for the Healthcare Professional">
                    Title
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.title}
                        type="text"
                        placeholder="Please enter a title..."
                        defaultValue={props.title}
                        {...register(
                            'title',
                            {
                                required: true
                            })
                        }
                    />
                    {title.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('title', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.title && <InvalidFeedback>The `Title` field is required</InvalidFeedback>}
            </div>

            <div className="first_name">
                <Label
                    data-tooltip-id={`update-hcp-tooltip-${props.id}`}
                    data-tooltip-content="First name for Healthcare Professional">
                    First name
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.first_name}
                        type="text"
                        placeholder="Please enter a first name..."
                        defaultValue={props.first_name}
                        {...register(
                            'first_name',
                            {
                                required: true
                            })
                        }
                    />
                    {first_name.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('first_name', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.first_name && <InvalidFeedback>The `First Name` field is required</InvalidFeedback>}
            </div>

            <div className="last_name">
                <Label
                    data-tooltip-id={`update-hcp-tooltip-${props.id}`}
                    data-tooltip-content="Last name for Healthcare Professional">
                    Last name
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.last_name}
                        type="text"
                        placeholder="Please enter a last name..."
                        defaultValue={props.last_name}
                        {...register(
                            'last_name',
                            {
                                required: true
                            })
                        }
                    />
                    {last_name.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('last_name', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.last_name && <InvalidFeedback>The `Last Name` field is required</InvalidFeedback>}
            </div>

            <div className="email">
                <Label
                    data-tooltip-id={`update-hcp-tooltip-${props.id}`}
                    data-tooltip-content="Email for the Healthcare Professional">
                    Email
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.email}
                        type="text"
                        placeholder="Please enter an email..."
                        defaultValue={props.email}
                        {...register(
                            'email',
                            {
                                required: true,
                                validate: {
                                    isValidEmail: value => isEmailValid(value)
                                }
                            })
                        }
                    />
                    {email.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('email', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.email && errors.email.type === 'required' &&
                    <InvalidFeedback>The `Email` field is required</InvalidFeedback>
                }
                {errors.email && errors.email.type === 'isValidEmail' &&
                    <InvalidFeedback>Please enter a valid email</InvalidFeedback>
                }
            </div>

            <input
                className="trash"
                onClick={e => {
                    e.preventDefault()
                    props.handleDelete(props.id)
                }}
                alt="delete item"
                type="image"
                src={trashIcon}
                data-tooltip-id={`update-hcp-tooltip-${props.id}`}
                data-tooltip-content="Click to delete record"
            />

            <PrimaryButton
                className="update btn"
                type="submit"
                data-tooltip-id={`update-hcp-tooltip-${props.id}`}
                data-tooltip-content="Click to update record">
                UPDATE
            </PrimaryButton>

            <Tooltip
                id={`update-hcp-tooltip-${props.id}`}
                float={true}
            />
        </form>
    )
}


export default Form