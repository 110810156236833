import _ from "lodash"
import {auth} from "./index"
import {onAuthStateChanged} from 'firebase/auth'
import {helpDocsListener} from './help/listener'
import {resourcesDocListener} from './useful_information/listener'
import {superAdminsDocListener} from './super_admins/listener'
import {accountsDocListener} from './accounts/listener'
import {getPatients} from './patients/index'
import {patientCountListener} from './patients/patientCountListener'
import {healthcareProfessionalsListener} from "./healthcare_professionals/listener"
import ACTION from "../constants/actionType"
import {feedbackListener} from "./analytics/feedback"
import {appointmentListener} from "./appointments"
import {batch} from "react-redux"


const removeUser = () =>
    ({
        type: ACTION.AUTH_LOGOUT,
        payload: null
    })


const setUpListeners = async dispatch => {

    batch(() => {
        dispatch(
            getPatients()
        )

        dispatch(
            helpDocsListener()
        )

        dispatch(
            resourcesDocListener()
        )

        dispatch(
            superAdminsDocListener()
        )

        dispatch(
            accountsDocListener()
        )

        dispatch(
            healthcareProfessionalsListener()
        )

        dispatch(
            feedbackListener()
        )

        dispatch(
            appointmentListener()
        )

        dispatch(
            patientCountListener()
        )
    })


}

const removeListeners = async () => {

    if (_.isFunction(window.feedbackDocListener)) {
        window.feedbackDocListener()
        delete window.feedbackDocListener
    }

    if (_.isFunction(window.accountsDocListener)) {
        window.accountsDocListener()
        delete window.accountsDocListener
    }

    if (_.isFunction(window.usefulInformationDocListener)) {
        window.usefulInformationDocListener()
        delete window.usefulInformationDocListener
    }

    if (_.isFunction(window.helpDocsListener)) {
        window.helpDocsListener()
        delete window.helpDocsListener
    }

    if (_.isFunction(window.healthcareProfessionalsListener)) {
        window.healthcareProfessionalsListener()
        delete window.healthcareProfessionalsListener
    }

    if (_.isFunction(window.getPatientsListener)) {
        window.getPatientsListener()
        delete window.getPatientsListener
    }

    if (_.isFunction(window.superAdminsDocListener)) {
        window.superAdminsDocListener()
        delete window.superAdminsDocListener
    }

    if (_.isFunction(window.appointmentListener)) {
        window.appointmentListener()
        delete window.appointmentListener
    }

    if (_.isFunction(window.patientCountListener)) {
        window.patientCountListener()
        delete window.patientCountListener
    }

}

export const dataListeners = () => async (dispatch, getState) => {

    if (_.isFunction(window.authListener)) {
        window.authListener()
        delete window.authListener
    }

    window.authListener = onAuthStateChanged(auth, currentUser => {
        if (currentUser) {
            setUpListeners(dispatch)
        } else {
            removeListeners()
            dispatch(
                removeUser()
            )
        }
    })
}