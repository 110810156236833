import styled from 'styled-components'
import theme from "./theme"


const NoData = styled.p`
  font-size: 1.6rem;
  text-align: center;
  margin-top: 100px;
`

NoData.defaultProps = {
    theme: theme
}

export default NoData