import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import Role from '../../constants/Role'
import {db} from '../index'
import {collection, doc, getDocs, increment, query, setDoc, Timestamp, where} from "firebase/firestore"
import {addError} from "../error"
import getKeywords from "../../util/getKeywords"


const isFetching = fetching =>
    ({
        type: ACTION.SUPER_ADMIN_FETCHING_DOCS,
        payload: fetching
    })

export const addItem = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        const superAdminsCollRef = collection(db, COLLECTION.SUPER_ADMINS)

        const q = query(superAdminsCollRef, where("admin_id", "==", item.admin_id))

        const querySnapshot = await getDocs(q)

        if (!querySnapshot.empty) {
            throw new Error(`CMS Admin with id: ${item.admin_id} already exists.  Please use a unique cms admin id.`)
        }

        const temp = {
            admin_id: item.admin_id,
            password: item.password,
            first_name: item.first_name,
            last_name: item.last_name,
            email: item.email,
            registered: Timestamp.now(),
            is_auth: false,
            keywords: getKeywords(item.admin_id, item.first_name, item.last_name, item.email)
        }

        const uid = doc(superAdminsCollRef).id + Role.SUPER_ADMIN

        await setDoc(doc(db, COLLECTION.SUPER_ADMINS, uid), temp)

        const superAdminsCountersRef = doc(db, COLLECTION.COUNTERS, COLLECTION.SUPER_ADMINS)

        await setDoc(superAdminsCountersRef, {total: increment(1)}, {merge: true})

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}