import styled from "styled-components"
import FormField from "./FormField"
import theme from "./theme"


const FormFieldWrapper = styled(FormField)`
  .rdt > input.form-control {
    border: 1px solid #C5C5C6;
    border-radius: 5px;
    color: hsl(0, 0%, 20%);
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    padding: 5px;
    line-height: 1.5;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.2s ease-in;

    &:focus {
      outline: none !important;
      border: 1px solid #2684FF;
    }
`

FormFieldWrapper.defaultProps = {
    theme: theme
}

export default FormFieldWrapper