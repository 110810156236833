import C from '../../constants/actionType'
import {db} from '../index'
import {collection, doc, getDocs, increment, query, setDoc, Timestamp, where, writeBatch} from "firebase/firestore"
import _ from 'lodash'
import {addError} from "../error"
import COLLECTION from '../../constants/collections'
import getKeywords from '../../util/getKeywords'
import Role from "../../constants/Role"
import XlsxPopulate from 'xlsx-populate'
import {batch} from "react-redux"


export const setDataProcessingStage = stage =>
    ({
        type: C.DATA_PROCESSING_STAGE,
        payload: stage
    })

const isFetching = fetching =>
    ({
        type: C.DATA_FETCHING_DOCS,
        payload: fetching
    })

const date = () => (new Date()).toISOString().slice(0, 19).replace(/-/g, "-").replace("T", "-").replace(":", "-")

const download = (data, filename, type) => {
    const file = new Blob([data], {type: type})
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file, filename)
    } else {
        const a = document.createElement("a")
        const url = URL.createObjectURL(file)
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        setTimeout(() => {
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
        }, 0)
    }
}


const postData = async (json, addRecords, dispatch) => {

    batch(() => {
        dispatch(
            isFetching(true)
        )
        dispatch(
            setDataProcessingStage("ACTIVE")
        )
    })

    try {

        const erroneousData = []
        let patientIncrement = 0
        let hcpIncrement = 0
        const hcpCollRef = collection(db, COLLECTION.HEALTH_CARE_PROFESSIONALS)
        const patientsCollRef = collection(db, COLLECTION.PATIENTS)
        const accountsCollRef = collection(db, COLLECTION.ACCOUNTS)
        const hcpCountersRef = doc(db, COLLECTION.COUNTERS, COLLECTION.HEALTH_CARE_PROFESSIONALS)
        const patientsCountersRef = doc(db, COLLECTION.COUNTERS, COLLECTION.PATIENTS)

        if (addRecords) {
            const chunks = _.chunk(json, 300)

            for (const chunk of chunks) {

                const batch = writeBatch(db)

                loopItem:
                    for (const item of chunk) {

                        if (item.hasOwnProperty('hcp_id')) {

                            const hcp_id = item.hcp_id.toString().toLowerCase().replace(/\s/g, '')
                            const account_id = item.account_id?.toLowerCase().replace(/\s/g, '') ?? 'all'
                            const password = item.password?.toString()?.toLowerCase()?.replace(/\s/g, '') ?? '20home19!'
                            const title = item.title?.trim() ?? "n/a"
                            const first_name = item.first_name?.trim() ?? "n/a"
                            const last_name = item.last_name?.trim() ?? "n/a"
                            const hcp_type = item.hcp_type?.trim() ?? "n/a"
                            const email = item.email?.toLowerCase()?.trim() ?? "n/a"

                            if (hcp_id.length === 0) {
                                dispatch(
                                    addError(`Healthcare Professional ID missing: ${hcp_id}`)
                                )

                                erroneousData.push(item)

                                continue;
                            }

                            if (account_id.length === 0) {
                                dispatch(
                                    addError(`Account ID missing: ${account_id}`)
                                )

                                erroneousData.push(item)

                                continue;
                            }

                            const q = query(hcpCollRef, where("hcp_id", "==", hcp_id))

                            const hcpQuerySnapshot = await getDocs(q)

                            if (!hcpQuerySnapshot.empty) {
                                dispatch(
                                    addError(`HCP ID ${item.hcp_id} already exists`)
                                )

                                erroneousData.push(item)

                                continue;
                            }

                            if (account_id !== 'all') {

                                const qAccounts = query(accountsCollRef, where("account_id", "==", account_id))

                                const aQuerySnapshot = await getDocs(qAccounts)

                                if (aQuerySnapshot.empty) {
                                    dispatch(
                                        addError(`Account ID ${item.account_id} not found`)
                                    )

                                    erroneousData.push(item)

                                    continue;
                                }
                            }

                            const uid = doc(hcpCollRef).id + Role.HEALTH_CARE_PROFESSIONAL

                            const hcpRef = doc(db, COLLECTION.HEALTH_CARE_PROFESSIONALS, uid)

                            batch.set(hcpRef, {
                                hcp_id: hcp_id,
                                account_id: account_id,
                                password: password,
                                title: title,
                                first_name: first_name,
                                last_name: last_name,
                                hcp_type: hcp_type,
                                email: email,
                                online: {
                                    status: false,
                                    last_updated: Timestamp.now()
                                },
                                is_auth: false,
                                registered: Timestamp.now(),
                                keywords: getKeywords(hcp_id, item.first_name, item.last_name, item.hcp_type, item.email)
                            })

                            hcpIncrement++

                        } else if (item.hasOwnProperty('patient_id')) {

                            const patient_id = item.patient_id?.toString()?.toLowerCase()?.replace(/\s/g, '')
                            const account_id = item.account_id?.toString()?.toLowerCase()?.replace(/\s/g, '') ?? 'all'
                            const password = item.password?.toLowerCase()?.replace(/\s/g, '') ?? '20home19!'


                            if (patient_id.length === 0) {
                                dispatch(
                                    addError(`Patient ID missing: ${patient_id}`)
                                )

                                erroneousData.push(item)

                                continue;
                            }

                            if (account_id.length === 0) {
                                dispatch(
                                    addError(` Account ID missing: ${account_id}`)
                                )

                                erroneousData.push(item)

                                continue;
                            }

                            const q = query(patientsCollRef, where("patient_id", "==", patient_id))

                            const pQuerySnapshot = await getDocs(q)

                            if (!pQuerySnapshot.empty) {
                                dispatch(
                                    addError(`Patient ID already exists: ${patient_id}.`)
                                )

                                erroneousData.push(item)

                                continue;
                            }

                            if (account_id !== 'all') {

                                const qAccounts = query(accountsCollRef, where("account_id", "==", account_id))

                                const aQuerySnapshot = await getDocs(qAccounts)

                                if (aQuerySnapshot.empty) {
                                    dispatch(
                                        addError(`Account ID ${account_id} not found`)
                                    )

                                    erroneousData.push(item)

                                    continue;
                                }
                            }

                            const uid = doc(patientsCollRef).id + Role.PATIENT

                            const patientRef = doc(db, COLLECTION.PATIENTS, uid)

                            batch.set(patientRef, {
                                account_id: account_id,
                                patient_id: patient_id,
                                password: password,
                                online: {
                                    status: false,
                                    last_updated: Timestamp.now()
                                },
                                is_auth: false,
                                registered: Timestamp.now(),
                                keywords: getKeywords(patient_id)
                            })

                            patientIncrement++

                        } else {
                            dispatch(
                                addError(`excel file improper formatting for ${JSON.stringify(item, null, 2)}`)
                            )
                        }

                    }

                await batch.commit()
            }

            if (erroneousData.length > 0) {
                const message = `${Object.keys(erroneousData[0]).join(",")}\n${erroneousData.map(i => Object.values(i).join(",")).join("\n")}`
                download(message, `error-${date()}`, "text/csv")
            }

        } else {

            const chunks = _.chunk(json, 400)

            for (const chunk of chunks) {

                const batch = writeBatch(db)

                for (const item of chunk) {

                    if (item.hasOwnProperty('hcp_id')) {
                        const hcp_id = item.hcp_id.toString().toLowerCase().replace(/\s/g, '')

                        const q = query(hcpCollRef, where("hcp_id", "==", hcp_id))

                        const querySnapshot = await getDocs(q)

                        if (!querySnapshot.empty) {
                            hcpIncrement -= querySnapshot.docs.length
                            querySnapshot.forEach(snap => {
                                const ref = doc(db, COLLECTION.HEALTH_CARE_PROFESSIONALS, snap.id)
                                batch.delete(ref)
                            })
                        } else {
                            erroneousData.push(item)
                        }

                    } else if (item.hasOwnProperty('patient_id')) {
                        const patient_id = item.patient_id.toString().toLowerCase().replace(/\s/g, '')

                        const q = query(patientsCollRef, where("patient_id", "==", patient_id))

                        const querySnapshot = await getDocs(q)

                        if (!querySnapshot.empty) {
                            patientIncrement -= querySnapshot.docs.length
                            querySnapshot.forEach(snap => {
                                const ref = doc(db, COLLECTION.PATIENTS, snap.id)
                                batch.delete(ref)
                            })
                        } else {
                            erroneousData.push(item)
                        }


                    } else {
                        dispatch(
                            addError(`Excel file improper formatting for ${JSON.stringify(item, null, 2)}`)
                        )
                    }
                }

                await batch.commit()
            }

            if (erroneousData.length > 0) {
                const message = `${Object.keys(erroneousData[0]).join(",")}\n${erroneousData.map(i => Object.values(i).join(",")).join("\n")}`
                download(message, `error-${date()}`, "text/csv")
            }

        }

        if (hcpIncrement !== 0) {
            await setDoc(hcpCountersRef, {total: increment(hcpIncrement)}, {merge: true})
        }

        if (patientIncrement !== 0) {
            await setDoc(patientsCountersRef, {total: increment(patientIncrement)}, {merge: true})
        }
    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        batch(() => {
            dispatch(
                isFetching(false)
            )
            dispatch(
                setDataProcessingStage("COMPLETED")
            )
        })
    }

}


export const uploadDataDocs = (inputFile, addRecords) => async (dispatch, getState) => {
    dispatch(
        isFetching(true)
    )

    try {
        const workbook = await XlsxPopulate.fromDataAsync(inputFile)
        const raw = await workbook.activeSheet().usedRange().value().filter(i => !i.includes(undefined))

        const header = raw.shift()

        if (raw.length > 0 && header.includes("hcp_id")) {

            const data = _.chain(raw).map(i => (_.zipObject(header, i))).uniqBy("hcp_id").value()

            postData(data, addRecords, dispatch)

        } else if (raw.length > 0 && header.includes("patient_id")) {

            const data = _.chain(raw).map(i => (_.zipObject(header, i))).uniqBy("patient_id").value()

            postData(data, addRecords, dispatch)

        } else {
            batch(() => {
                dispatch(
                    addError(`excel file improper formatting`)
                )

                dispatch(
                    isFetching(false)
                )
            })
        }

    } catch (error) {
        batch(() => {
            dispatch(
                addError(error.message)
            )

            dispatch(
                isFetching(false)
            )
        })
    }
}

