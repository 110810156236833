import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {deleteDoc, doc, increment, setDoc} from "firebase/firestore"
import {addError} from "../error"


const isFetching = fetching =>
    ({
        type: ACTION.HELP_FETCHING_DOCS,
        payload: fetching
    })

export const deleteItem = itemID => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        await deleteDoc(doc(db, COLLECTION.HELP, itemID))

        const helpCounterRef = doc(db, COLLECTION.COUNTERS, COLLECTION.HELP)

        await setDoc(helpCounterRef, {total: increment(-1)}, {merge: true})

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}

export const setItem = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        const temp = {
            account_id: item.account_id,
            title: item.title,
            body: item.body,
            index: item.index,
            group: item.group,
            with_link: item.with_link,
            keywords: item.keywords
        }

        if (item.with_link) {
            temp.link_title = item.link_title
            temp.link_url = item.link_url
        }

        await setDoc(doc(db, COLLECTION.HELP, item.id), temp, {merge: true})

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}