import {initializeApp} from "firebase/app"
import {connectAuthEmulator, getAuth} from 'firebase/auth'
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore"
import {connectFunctionsEmulator, getFunctions} from "firebase/functions"
import {getPerformance} from "firebase/performance"
import {initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check"
import {getAnalytics} from 'firebase/analytics'
import Region from "../constants/region"
import {captcha_site_key, config} from '../constants/config'

const app = initializeApp(config)


export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(captcha_site_key),
    isTokenAutoRefreshEnabled: true
})


export const analytics = getAnalytics(app)
export const db = getFirestore(app)
export const auth = getAuth(app)
export const functions = getFunctions(app, Region)
export const performance = getPerformance(app)

if (window.location.hostname === "127.0.0.1") {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001)
    connectFirestoreEmulator(db, "127.0.0.1", 8080)
    connectAuthEmulator(auth, "http://localhost:9099")
}