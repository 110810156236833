import {combineReducers} from 'redux'
import auth from './auth'
import appointments from './appointments'
import errors from './errors'
import help from './help'
import useful_information from './useful_information'
import accounts from './accounts'
import super_admins from './super_admins'
import healthcare_professionals from './healthcare_professionals'
import patients from './patients'
import feedback from './analytics'
import data from './data'


export default combineReducers({
    errors: errors,
    auth: auth,
    data: data,
    help: help,
    appointments: appointments,
    useful_information: useful_information,
    feedback: feedback,
    healthcare_professionals: healthcare_professionals,
    patients: patients,
    accounts: accounts,
    super_admins: super_admins
})