import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import close from '../../../assets/svg/close.svg'
import trashIcon from '../../../assets/svg/trash.svg'
import '../../../assets/scss/Accounts/Add.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import ResetButton from "../Styled/ResetButton"
import {useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import TrashButton from "../Styled/TrashButton"
import PrimaryButton from "../Styled/PrimaryButton"
import VersionNumber from "../Styled/VersionNumber"
import {logEvent} from "firebase/analytics"


const Add = ({addItem, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Add Account"
    })

    const navigate = useNavigate()

    const {register, handleSubmit, setValue, watch, formState: {errors}, reset} = useForm({
        mode: 'onSubmit',
        defaultValues: {
            account_id: "",
            display_name: ""
        }
    })

    const onSubmit = data => {

        addItem(data)

        navigate('/accounts')
    }

    const [account_id, display_name] = watch(['account_id', 'display_name'])

    return (
        <main className="app add-account">
            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Add Account"/>

                <section className="content">

                    <h3>New Account</h3>

                    <Link
                        to="/accounts"
                        className="close"
                        data-tooltip-id="add-account-tooltip"
                        data-tooltip-content="Click to close">
                        <img
                            src={close}
                            alt="close"
                        />
                    </Link>

                    <form
                        className="add-account-form"
                        onSubmit={handleSubmit(onSubmit)}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                        }}
                    >

                        <div className="account_id">
                            <Label
                                data-tooltip-id="add-account-tooltip"
                                data-tooltip-content="The account id for the item">
                                Account ID
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.account_id}
                                    tabIndex="10"
                                    type="text"
                                    placeholder="Please enter an account ID..."
                                    {...register(
                                        'account_id',
                                        {
                                            required: true,
                                            validate: {
                                                lowerCase: value => value.toLowerCase() === value,
                                                noSpaces: value => value.replace(" ", "") === value,
                                                longerThanThreeLetters: value => value.length > 2
                                            }
                                        })
                                    }
                                />
                                {account_id.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('account_id', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.account_id && errors.account_id.type === 'required' &&
                                <InvalidFeedback>The `Account ID` field is required</InvalidFeedback>
                            }
                            {errors.account_id && errors.account_id.type === 'lowerCase' &&
                                <InvalidFeedback>The `Account ID` needs to be in lowercase</InvalidFeedback>
                            }
                            {errors.account_id && errors.account_id.type === 'noSpaces' &&
                                <InvalidFeedback>The `Account ID` cannot have spaces</InvalidFeedback>
                            }
                            {errors.account_id && errors.account_id.type === 'longerThanThreeLetters' &&
                                <InvalidFeedback>The `Account ID` must be at least 3 characters</InvalidFeedback>
                            }
                        </div>

                        <div className="display_name">
                            <Label
                                data-tooltip-id="add-account-tooltip"
                                data-tooltip-content="The Account name to display">
                                Display Name
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.display_name}
                                    tabIndex="20"
                                    type="text"
                                    placeholder="Please enter an account name..."
                                    {...register(
                                        'display_name',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {display_name.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('display_name', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.display_name &&
                                <InvalidFeedback>The `Display Name` field is required</InvalidFeedback>}
                        </div>

                        <TrashButton
                            className="reset"
                            tabIndex="30"
                            onClick={() => reset()}
                            type="image"
                            alt="TrashButton"
                            src={trashIcon}
                            data-tooltip-id="add-account-tooltip"
                            data-tooltip-content="Click to reset record"
                        />

                        <PrimaryButton
                            data-tooltip-id="add-account-tooltip"
                            data-tooltip-content="Click to add record"
                            tabIndex="40"
                            className="add btn"
                            type="submit"
                        >ADD ACCOUNT</PrimaryButton>

                        <Tooltip
                            id="add-account-tooltip"
                            float={true}
                        />
                    </form>

                </section>
            </article>
            <VersionNumber/>
        </main>
    )

}

export default Add