import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {deleteDoc, doc, increment, setDoc} from "firebase/firestore"
import {addError} from "../error"
import getKeywords from "../../util/getKeywords"


const isFetching = fetching =>
    ({
        type: C.HEALTHCARE_PROFESSIONALS_FETCHING_DOCS,
        payload: fetching
    })


export const deleteItem = itemID => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        await deleteDoc(doc(db, COLLECTION.HEALTH_CARE_PROFESSIONALS, itemID))

        const hcpCounterRef = doc(db, COLLECTION.COUNTERS, COLLECTION.HEALTH_CARE_PROFESSIONALS)

        await setDoc(hcpCounterRef, {total: increment(-1)}, {merge: true})

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}

export const setItem = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {

        const temp = {
            account_id: item.account_id,
            first_name: item.first_name,
            last_name: item.last_name,
            email: item.email,
            title: item.title,
            hcp_type: item.hcp_type,
            password: item.password,
            keywords: getKeywords(item.hcp_id, item.first_name, item.last_name, item.email, item.hcp_type)
        }

        await setDoc(doc(db, COLLECTION.HEALTH_CARE_PROFESSIONALS, item.id), temp, {merge: true})

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}