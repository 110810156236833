const roleType = [{
    label: "All groups",
    value: "all"
}, {
    label: "Patients",
    value: "patient"
}, {
    label: "Healthcare Professionals",
    value: "health-care-professional"
}]

export default roleType