import C from '../../../constants/actionType'
import {combineReducers} from 'redux'
import _ from 'lodash'
import moment from "moment"


const fetching = (state = false, action) =>
    (action.type === C.APPOINTMENT_FETCHING_DOCS) ?
        action.payload :
        state

const queryString = (state = "", action) =>
    (action.type === C.APPOINTMENT_QUERY_TERM) ?
        action.payload :
        state

const date = (state = moment().toDate(), action) =>
    (action.type === C.APPOINTMENT_QUERY_DATE_RANGE) ?
        action.payload :
        state

const account_id = (state = "all", action) =>
    (action.type === C.APPOINTMENT_QUERY_ACCOUNT_ID) ?
        action.payload :
        state

const docs = (state = [], action) => {

    let newDoc, docID, docIndex

    switch (action.type) {
        case C.APPOINTMENT_NEW_DOC :
            newDoc = action.payload
            docID = newDoc.id
            docIndex = _.findIndex(state, doc => doc.id === docID)

            return docIndex < 0 ? _.orderBy([...state, newDoc], ['date'], ['desc']) : state
        case C.APPOINTMENT_UPDATE_DOC :
            newDoc = action.payload
            docID = newDoc.id
            docIndex = _.findIndex(state, doc => doc.id === docID)

            return [
                ...state.slice(0, docIndex),
                newDoc,
                ...state.slice(docIndex + 1)
            ]
        case C.APPOINTMENT_DELETE_DOC :
            newDoc = action.payload
            docID = newDoc.id
            return _.reject(state, doc => doc.id === docID)
        default :
            return state
    }
}

export default combineReducers({
    docs,
    fetching,
    queryString,
    date,
    account_id
})