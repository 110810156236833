import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {addDoc, collection, deleteDoc, doc, onSnapshot, query, Timestamp, updateDoc, where} from "firebase/firestore"
import {addError} from "../error"
import _ from 'lodash'
import moment from "moment"
import getKeywords from "../../util/getKeywords"
import AppointmentColors from "../../util/AppointmentColors"
import {workerThread} from "../../util/workerThread"
import {batch} from "react-redux"


const isFetching = fetching =>
    ({
        type: C.APPOINTMENT_FETCHING_DOCS,
        payload: fetching
    })

export const addQuery = queryString =>
    ({
        type: C.APPOINTMENT_QUERY_TERM,
        payload: queryString
    })

export const addDate = date =>
    ({
        type: C.APPOINTMENT_QUERY_DATE_RANGE,
        payload: date
    })

export const addAccountID = company_id =>
    ({
        type: C.APPOINTMENT_QUERY_ACCOUNT_ID,
        payload: company_id
    })

const removeItem = item =>
    ({
        type: C.APPOINTMENT_DELETE_DOC,
        payload: item
    })

const updateItem = item =>
    ({
        type: C.APPOINTMENT_UPDATE_DOC,
        payload: item
    })

const addItem = item =>
    ({
        type: C.APPOINTMENT_NEW_DOC,
        payload: item
    })

export const cancelAppointment = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {

        await deleteDoc(doc(db, COLLECTION.APPOINTMENTS, item.id))

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }
}


export const appointmentListener = () => async (dispatch, getState) => {

    const {appointments: {docs = [], queryString = "", date = moment().toDate(), account_id = "all"}} = getState()

    dispatch(
        isFetching(true)
    )

    const queryConstraints = []

    queryConstraints.push(where('date', '>=', moment(date).startOf('month').subtract(16, 'd').startOf('day').toDate()))
    queryConstraints.push(where('date', '<=', moment(date).endOf('month').add(16, 'd').endOf('day').toDate()))

    if (account_id !== "all") {
        queryConstraints.push(where('account_id', '==', account_id))
    }

    if (queryString.length > 0) {
        queryConstraints.push(where("keywords", "array-contains", queryString.toLowerCase()))
    }

    if (_.isFunction(window.appointmentListener)) {
        window.appointmentListener()
        delete window.appointmentListener
    }

    const appointmentsCollRef = collection(db, COLLECTION.APPOINTMENTS)

    const q = query(
        appointmentsCollRef,
        ...queryConstraints
    )

    window.appointmentListener = onSnapshot(q, querySnapshot => {

        dispatch(
            isFetching(false)
        )

        const queryDocs = querySnapshot.docs.map(i => ({id: i.id, ...i.data()}))

        docs.filter(i => !queryDocs.find(j => i.id === j.id))
            .forEach(i => dispatch(removeItem(i)))

        workerThread(() => {
            querySnapshot.docChanges().forEach(change => {

                const item = {id: change.doc.id, ...change.doc.data()}

                if (_.has(item, "date")) {
                    item.date = item.date.toMillis()
                }

                item.hexColor = AppointmentColors.hexColor(item.health_care_professional.hcp_type)

                switch (change.type) {
                    case 'added':
                        dispatch(
                            addItem(item)
                        )
                        break
                    case 'modified':
                        dispatch(
                            updateItem(item)
                        )
                        break
                    case 'removed':
                        dispatch(
                            removeItem(item)
                        )
                        break
                    default:
                        break
                }

            })
        })
    }, error => {
        batch(() => {
            dispatch(
                isFetching(false)
            )
            dispatch(
                addError(error.message)
            )
        })
    })
}


export const setAppointment = (hcpDoc, patientDoc, date) => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {

        const account_id = hcpDoc.account_id

        const patient = {
            patient_id: patientDoc.patient_id,
            uid: patientDoc.id,
            keywords: getKeywords(patientDoc.patient_id)
        }

        const hcp = {
            hcp_id: hcpDoc.hcp_id,
            uid: hcpDoc.id,
            first_name: hcpDoc.first_name,
            last_name: hcpDoc.last_name,
            email: hcpDoc.email,
            title: hcpDoc.title,
            hcp_type: hcpDoc.hcp_type,
            keywords: getKeywords(hcpDoc.hcp_id, hcpDoc.first_name, hcpDoc.last_name, hcpDoc.email, hcpDoc.title, hcpDoc.hcp_type)
        }

        const temp = {
            account_id: account_id,
            date: Timestamp.fromDate(date.toDate()),
            patient: patient,
            health_care_professional: hcp,
            keywords: getKeywords(patientDoc.patient_id, hcpDoc.hcp_id, hcpDoc.first_name, hcpDoc.last_name, hcpDoc.email, hcpDoc.title, hcpDoc.hcp_type)
        }

        await addDoc(collection(db, COLLECTION.APPOINTMENTS), temp)

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}

export const rescheduleAppointment = (
    appointment,
    date
) => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        const appointmentRef = doc(db, COLLECTION.APPOINTMENTS, appointment.id)

        await updateDoc(appointmentRef, {
            date: Timestamp.fromDate(date)
        })

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}