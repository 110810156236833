import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import getKeywords from '../../../util/getKeywords'
import trashIcon from '../../../assets/svg/trash.svg'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/Help/Add.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import roleType from '../../../constants/roleType'
import Select from 'react-select'
import isUrlValid from '../../../util/isUrlValid'
import InputNumber from "rc-input-number"
import 'rc-input-number/assets/index.css'
import ResetButton from "../Styled/ResetButton"
import {Controller, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Textarea from "../Styled/Textarea"
import Label from "../Styled/Label"
import TrashButton from "../Styled/TrashButton"
import colourStyles from "../../../constants/colourStyles"
import PrimaryButton from "../Styled/PrimaryButton"
import VersionNumber from "../Styled/VersionNumber"
import {logEvent} from "firebase/analytics"


const Add = ({options, addItem, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Add Help"
    })

    const navigate = useNavigate()

    const {register, handleSubmit, setValue, watch, formState: {errors}, reset, control} = useForm({
        mode: 'onSubmit',
        defaultValues: {
            account_id: {label: 'All accounts', value: 'all'},
            title: "",
            body: "",
            group: {label: "All groups", value: "all"},
            index: 1,
            with_link: false
        }
    })

    const onSubmit = data => {
        data.account_id = data.account_id.value
        data.group = data.group.value

        data.keywords = data.with_link ?
            getKeywords(data.title, data.body, data.link_title, data.link_url) :
            getKeywords(data.title, data.body)

        addItem(data)

        navigate('/help')
    }

    const [title, with_link, link_title, link_url] = watch(["title", "with_link", "link_title", "link_url"])

    window.greg = errors

    return (
        <main className="app add-help">
            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Add Help"/>

                <section className="content">

                    <h3>New Help</h3>
                    <Link
                        to="/help"
                        className="close"
                        data-tooltip-id="add-help-tooltip"
                        data-tooltip-content="Click to close">
                        <img
                            src={close}
                            alt="close"
                        />
                    </Link>

                    <form
                        className="add-help-form"
                        onSubmit={handleSubmit(onSubmit)}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                        }}
                    >

                        <div className="account_id">
                            <Label
                                data-tooltip-id="add-help-tooltip"
                                data-tooltip-content="The Account ID for the item">
                                Account ID
                            </Label>
                            <Controller
                                render={({field: {onChange, value, name, ref}}) => (
                                    <Select
                                        value={value}
                                        name={name}
                                        options={options}
                                        onChange={onChange}
                                        className="react-select-field"
                                        placeholder="Please select an account..."
                                        styles={colourStyles}
                                        ref={ref}
                                    />
                                )}
                                control={control}
                                rules={{required: true}}
                                name="account_id"
                            />
                            {errors.account_id && <InvalidFeedback>The `Account ID` field is required</InvalidFeedback>}
                        </div>

                        <div className="group">
                            <Label
                                data-tooltip-id="add-help-tooltip"
                                data-tooltip-content="The group for the item">
                                Group
                            </Label>
                            <Controller
                                render={({field: {onChange, value, name, ref}}) => (
                                    <Select
                                        value={value}
                                        name={name}
                                        options={roleType}
                                        onChange={onChange}
                                        className="react-select-field"
                                        placeholder="Please select an group..."
                                        styles={colourStyles}
                                        ref={ref}
                                    />
                                )}
                                control={control}
                                rules={{required: true}}
                                name="group"
                            />
                        </div>

                        <div className="title">
                            <Label
                                data-tooltip-id="add-help-tooltip"
                                data-tooltip-content="The title for the item">
                                Title
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.title}
                                    type="text"
                                    placeholder="Please enter a title..."
                                    {...register(
                                        'title',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {title.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('title', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.title && <InvalidFeedback>The `Title` field is required</InvalidFeedback>}
                        </div>

                        <div className="body">
                            <Label
                                data-tooltip-id="add-help-tooltip"
                                data-tooltip-content="The description for the item">
                                Description (500 word limit)
                            </Label>
                            <Textarea
                                placeholder="Please enter the description..."
                                isValid={!!errors.body}
                                {...register(
                                    'body',
                                    {
                                        required: true,
                                        maxLength: 1000
                                    })
                                }
                            />
                            {errors.body && errors.body.type === "maxLength" &&
                                <InvalidFeedback>The description needs to be less than 500 words</InvalidFeedback>
                            }
                            {errors.body && errors.body.type === "required" &&
                                <InvalidFeedback>The `Description` field is required</InvalidFeedback>
                            }
                        </div>

                        <label className="checkbox">
                            <input
                                type="checkbox"
                                {...register('with_link')}
                            />
                            <span data-tip={`Does this item include a link`}>Link</span>
                        </label>

                        {with_link && (
                            <>
                                <div className="link_title">
                                    <Label
                                        data-tooltip-id="add-help-tooltip"
                                        data-tooltip-content="The link title">
                                        Link title
                                    </Label>
                                    <div style={{position: "relative"}}>
                                        <InputField
                                            isValid={!!errors.link_title}
                                            type="text"
                                            placeholder="Please enter the link title..."
                                            {...register(
                                                'link_title',
                                                {
                                                    required: true
                                                })
                                            }
                                        />
                                        {!!link_title && link_title.length > 0 &&
                                            <ResetButton
                                                onClick={() => setValue('link_title', '')}
                                                type="reset"
                                            />
                                        }
                                    </div>
                                    {errors.link_title &&
                                        <InvalidFeedback>The `Link Title` field is required</InvalidFeedback>}
                                </div>

                                <div className="link_url">
                                    <Label
                                        data-tooltip-id="add-help-tooltip"
                                        data-tooltip-content="The link url">
                                        Link Url
                                    </Label>
                                    <div style={{position: "relative"}}>
                                        <InputField
                                            isValid={!!errors.link_url}
                                            type="text"
                                            placeholder="Please enter the link url..."
                                            {...register(
                                                'link_url',
                                                {
                                                    required: true,
                                                    validate: {
                                                        isValidUrl: value => isUrlValid(value)
                                                    }
                                                })
                                            }
                                        />
                                        {!!link_url && link_url.length > 0 &&
                                            <ResetButton
                                                onClick={() => setValue('link_url', '')}
                                                type="reset"
                                            />
                                        }
                                    </div>
                                    {errors.link_url && errors.link_url.type === "required" &&
                                        <InvalidFeedback>The `Link URL` field is required</InvalidFeedback>
                                    }
                                    {errors.link_url && errors.link_url.type === 'isValidUrl' &&
                                        <InvalidFeedback>Please enter a valid url</InvalidFeedback>
                                    }
                                </div>
                            </>
                        )}

                        <div className="index">
                            <Label
                                data-tooltip-id="add-help-tooltip"
                                data-tooltip-content="The rank order for the item">
                                Order
                            </Label>
                            <Controller
                                render={({field: {onChange, value, name, ref}}) => (
                                    <InputNumber
                                        name={name}
                                        min={1}
                                        value={value}
                                        onChange={onChange}
                                        ref={ref}
                                    />
                                )}
                                control={control}
                                rules={{required: true}}
                                name="index"
                            />
                        </div>

                        <TrashButton
                            className="reset"
                            onClick={() => reset()}
                            type="image"
                            src={trashIcon}
                            alt="reset"
                            data-tooltip-id="add-help-tooltip"
                            data-tooltip-content="Click to reset record"
                        />

                        <PrimaryButton
                            data-tooltip-id="add-help-tooltip"
                            data-tooltip-content="Click to add record"
                            className="add btn"
                            type="submit">
                            ADD HELP
                        </PrimaryButton>

                        <Tooltip
                            id="add-help-tooltip"
                            float={true}
                        />

                    </form>

                </section>
            </article>
            <VersionNumber/>
        </main>
    )
}


export default Add