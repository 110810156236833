import Login from '../../ui/Login/Login'
import {connect} from 'react-redux'
import {signInWithAdminIDAndPassword} from '../../../actions/login'
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        user: state.auth.user,
        fetching: state.auth.fetching,
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({
        signInWithAdminIDAndPassword(cms_admin_id, password) {
            dispatch(
                signInWithAdminIDAndPassword(cms_admin_id, password)
            )
        }
    })


export default connect(mapStateToProps, mapDispatchToProps)(Login)