import styled from "styled-components"
import theme from "./theme"


const DrawerHandel = styled.div`
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  z-index: 0;
  left: -40px;
  cursor: pointer;
  box-shadow: -4px 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
  background: #fff url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 30 30' width='30' height='30' xml:space='preserve'%3E%3Cpath stroke='none' fill='%23FF6100' d='M 18.18,15 L 27.8,5.39 29.78,3.4 C 30.07,3.11 30.07,2.63 29.78,2.34 L 27.66,0.22 C 27.37,-0.07 26.89,-0.07 26.6,0.22 L 15,11.82 3.4,0.22 C 3.11,-0.07 2.63,-0.07 2.34,0.22 L 0.22,2.34 C -0.07,2.63 -0.07,3.11 0.22,3.4 L 11.82,15 0.22,26.6 C -0.07,26.89 -0.07,27.37 0.22,27.66 L 2.34,29.78 C 2.63,30.07 3.11,30.07 3.4,29.78 L 15,18.18 24.62,27.8 26.6,29.78 C 26.89,30.07 27.37,30.07 27.66,29.78 L 29.78,27.66 C 30.07,27.37 30.07,26.89 29.78,26.6 L 18.18,15 Z M 18.18,15' /%3E%3C/svg%3E%0A") no-repeat center;
  background-size: 20px;
`

DrawerHandel.defaultProps = {
    theme: theme
}

export default DrawerHandel