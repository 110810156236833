import C from '../../../constants/actionType'
import {combineReducers} from 'redux'
import _ from 'lodash'
import moment from "moment-timezone"
import Timezone from "../../../constants/timezone"


const fetching = (state = false, action) =>
    (action.type === C.FEEDBACK_FETCHING_DOCS) ?
        action.payload :
        state

const start_date = (state = moment.tz(Timezone).subtract(6, 'd').valueOf(), action) =>
    (action.type === C.FEEDBACK_START_DATE) ?
        action.payload :
        state

const end_date = (state = moment.tz(Timezone).valueOf(), action) =>
    (action.type === C.FEEDBACK_END_DATE) ?
        action.payload :
        state

const account_id = (state = 'all', action) =>
    (action.type === C.FEEDBACK_ACCOUNT_ID) ?
        action.payload :
        state

const docs = (state = [], action) => {

    let newDoc, docID, docIndex


    switch (action.type) {
        case C.FEEDBACK_NEW_DOC :
            newDoc = action.payload
            docID = newDoc.id
            docIndex = _.findIndex(state, doc => doc.id === docID)

            return docIndex < 0 ? _.orderBy([...state, newDoc], ['date'], ['desc']) : state
        case C.FEEDBACK_UPDATE_DOC :
            newDoc = action.payload
            docID = newDoc.id
            docIndex = _.findIndex(state, doc => doc.id === docID)

            return [
                ...state.slice(0, docIndex),
                newDoc,
                ...state.slice(docIndex + 1)
            ]
        case C.FEEDBACK_DELETE_DOC :
            newDoc = action.payload
            docID = newDoc.id
            return _.reject(state, doc => doc.id === docID)
        case C.FEEDBACK_CLEAR_DOCS :
            return []
        default :
            return state
    }

}

export default combineReducers({
    docs,
    fetching,
    account_id,
    start_date,
    end_date
})