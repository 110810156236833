import Analytics from '../../ui/Analytics'
import {connect} from 'react-redux'
import {feedbackListener, setAccountID, setEndDate, setStartDate} from '../../../actions/analytics/feedback'
import {getData, getDates, getEndDate, getHCP, getOptions, getPatients, getStartDate} from "./selectors"
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        docs: state.feedback.docs,
        fetching: state.feedback.fetching,
        start_date: getStartDate(state),
        end_date: getEndDate(state),
        account_id: state.feedback.account_id,
        dates: getDates(state),
        patients: getPatients(state),
        hcp: getHCP(state),
        data: getData(state),
        options: getOptions(state),
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({
        fetchFeedback() {
            dispatch(
                feedbackListener()
            )
        },
        setAccountID(account_id) {
            dispatch(
                setAccountID(account_id)
            )
        },
        setStartDate(start_date) {
            dispatch(
                setStartDate(start_date)
            )
        },
        setEndDate(end_date) {
            dispatch(
                setEndDate(end_date)
            )
        }
    })


export default connect(mapStateToProps, mapDispatchToProps)(Analytics)
