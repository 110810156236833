import styled from "styled-components"
import theme from "./theme"


const Tabs = styled.ul`
  margin: 28px 0 0 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;

  > li {
    width: 100%;
    color: white;
    overflow: hidden;
    margin: 0 0 0.8rem 0;
    flex: 0 0 100%;
    border: 1px solid ${props => props.theme.brandSecondary};
    border-radius: 0.5rem;

    > input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      z-index: -1;

      &:checked {
        + .tab-label {
          &::after {
            content: "\\2212";
            text-align: center;
            width: 20px;
            display: flex;
            height: 20px;
            font-size: 30px;
            justify-content: center;
            align-self: center;
            align-items: center;
          }
        }

        ~ .tab-content {
          max-height: 1000vh;
          opacity: 1;
          padding: 1.6rem;
        }
      }
    }

    > label {
      display: flex;
      justify-content: space-between;
      padding: 0.7rem 1.6rem 0.7rem 1.6rem;
      color: ${props => props.theme.brandSecondary};
      font-family: ${props => props.theme.fontFamily};
      font-size: 2.2rem;
      font-weight: bold;
      cursor: pointer;
      user-select: none;

      &::after {
        content: "\\002B";
        display: flex;
        color: ${props => props.theme.brandPrimary};
        text-align: center;
        transition: all .15s ease-in-out;
        width: 20px;
        height: 20px;
        font-size: 30px;
        justify-content: center;
        align-self: center;
        align-items: center;
      }
    }

    > div {
      max-height: 0;
      padding: 0 1.6rem;
      overflow: hidden;
      opacity: 0;
      transition: all .2s ease-in-out;

      &:after {
        content: "";
        clear: both;
        display: table;
      }
    }
  }
`

Tabs.defaultProps = {
    theme: theme
}

export default Tabs