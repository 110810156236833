import React from 'react'
import '../../../assets/scss/Help/Form.scss'
import trashIcon from '../../../assets/svg/trash.svg'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import getKeywords from "../../../util/getKeywords"
import roleType from '../../../constants/roleType'
import isUrlValid from '../../../util/isUrlValid'
import Select from 'react-select'
import InputNumber from 'rc-input-number'
import 'rc-input-number/assets/index.css'
import ResetButton from "../Styled/ResetButton"
import {Controller, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Textarea from "../Styled/Textarea"
import Label from "../Styled/Label"
import TrashButton from "../Styled/TrashButton"
import PrimaryButton from "../Styled/PrimaryButton"
import colourStyles from "../../../constants/colourStyles"


const Form = props => {

    const {register, handleSubmit, setValue, watch, formState: {errors}, control} = useForm({mode: 'onSubmit'})

    const onSubmit = data => {
        data.id = props.id
        data.account_id = data.account_id.value
        data.group = data.group.value

        data.keywords = data.with_link ?
            getKeywords(data.title, data.body, data.link_title, data.link_url) :
            getKeywords(data.title, data.body)

        props.handleUpdate(data)
    }

    const title = watch("title", props.title)
    const with_link = watch("with_link", props.with_link)
    const link_title = watch("link_title", props.link_title)
    const link_url = watch("link_url", props.link_url)

    return (
        <form
            className="edit-help-form"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault()
            }}
        >

            <div className="account_id">
                <Label
                    data-tooltip-id={`update-help-tooltip-${props.id}`}
                    data-tooltip-content="The Account ID for the item">
                    Account ID
                </Label>
                <Controller
                    render={({field: {onChange, value, name, ref}}) => (
                        <Select
                            value={value}
                            name={name}
                            options={props.options}
                            onChange={onChange}
                            className="react-select-field"
                            placeholder="Please select an account..."
                            styles={colourStyles}
                            ref={ref}
                        />
                    )}
                    defaultValue={props.options.find(account => account.value === props.account_id)}
                    control={control}
                    rules={{required: true}}
                    name="account_id"
                />
                {errors.account_id && <InvalidFeedback>The `Account ID` field is required</InvalidFeedback>}
            </div>

            <div className="group">
                <Label
                    data-tooltip-id={`update-help-tooltip-${props.id}`}
                    data-tooltip-content="The group for the item">
                    Group
                </Label>
                <Controller
                    render={({field: {onChange, value, name, ref}}) => (
                        <Select
                            value={value}
                            name={name}
                            options={roleType}
                            onChange={onChange}
                            className="react-select-field"
                            placeholder="Please select a group..."
                            styles={colourStyles}
                            ref={ref}
                        />
                    )}
                    defaultValue={roleType.find(role => role.value === props.group)}
                    control={control}
                    rules={{required: true}}
                    name="group"
                />
            </div>

            <div className="title">
                <Label
                    data-tooltip-id={`update-help-tooltip-${props.id}`}
                    data-tooltip-content="The title for the item">
                    Title
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.title}
                        type="text"
                        placeholder="Please enter a title..."
                        defaultValue={props.title}
                        {...register(
                            'title',
                            {
                                required: true
                            })
                        }
                    />
                    {title.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('title', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.title && <InvalidFeedback>The `Title` field is required</InvalidFeedback>}
            </div>

            <div className="body">
                <Label
                    data-tooltip-id={`update-help-tooltip-${props.id}`}
                    data-tooltip-content="The description for the item">
                    Description (500 word limit)
                </Label>
                <Textarea
                    placeholder="Please enter the description..."
                    isValid={!!errors.body}
                    defaultValue={props.body}
                    {...register(
                        'body',
                        {
                            required: true,
                            maxLength: 1000
                        })
                    }
                />
                {errors.body && errors.body.type === "maxLength" &&
                    <InvalidFeedback>The description needs to be less than 500 words</InvalidFeedback>
                }
                {errors.body && errors.body.type === "required" &&
                    <InvalidFeedback>The `Description` field is required</InvalidFeedback>
                }            </div>

            <label className="checkbox">
                <input
                    type="checkbox"
                    defaultChecked={props.with_link}
                    {...register('with_link')}
                />
                <span data-tip={`Does this item include a link`}>Link</span>
            </label>

            {with_link && (
                <>
                    <div className="link_title">
                        <Label
                            data-tooltip-id={`update-help-tooltip-${props.id}`}
                            data-tooltip-content="The link title">
                            Link title
                        </Label>
                        <div style={{position: "relative"}}>
                            <InputField
                                isValid={!!errors.link_title}
                                type="text"
                                placeholder="Please enter the link title..."
                                defaultValue={props.link_title}
                                {...register(
                                    'link_title',
                                    {
                                        required: true
                                    })
                                }
                            />
                            {!!link_title && link_title.length > 0 &&
                                <ResetButton
                                    onClick={() => setValue('link_title', '')}
                                    type="reset"
                                />
                            }
                        </div>
                        {errors.link_title && <InvalidFeedback>The `Link Title` field is required</InvalidFeedback>}
                    </div>

                    <div className="link_url">
                        <Label
                            data-tooltip-id={`update-help-tooltip-${props.id}`}
                            data-tooltip-content="The link url">
                            Link Url
                        </Label>
                        <div style={{position: "relative"}}>
                            <InputField
                                isValid={!!errors.link_url}
                                type="text"
                                placeholder="Please enter the link url..."
                                defaultValue={props.link_url}
                                {...register(
                                    'link_url',
                                    {
                                        required: true,
                                        validate: {
                                            isValidUrl: value => isUrlValid(value)
                                        }
                                    })
                                }
                            />
                            {!!link_url && link_url.length > 0 &&
                                <ResetButton
                                    onClick={() => setValue('link_url', '')}
                                    type="reset"
                                />
                            }
                        </div>
                        {errors.link_url && errors.link_url.type === "required" &&
                            <InvalidFeedback>The `Link URL` field is required</InvalidFeedback>
                        }
                        {errors.link_url && errors.link_url.type === 'isValidUrl' &&
                            <InvalidFeedback>Please enter a valid url</InvalidFeedback>
                        }

                    </div>
                </>
            )}

            <div className="index">
                <Label
                    data-tooltip-id={`update-help-tooltip-${props.id}`}
                    data-tooltip-content="The rank order for the item">
                    Order
                </Label>
                <Controller
                    render={({field: {onChange, value, name, ref}}) => (
                        <InputNumber
                            name={name}
                            min={1}
                            value={value}
                            onChange={onChange}
                            ref={ref}
                        />
                    )}
                    defaultValue={props.index}
                    control={control}
                    rules={{required: true}}
                    name="index"
                />
            </div>

            <TrashButton
                className="trash"
                onClick={e => {
                    e.preventDefault()
                    props.handleDelete(props.id)
                }}
                type="image"
                alt="delete item"
                src={trashIcon}
                data-tooltip-id={`update-help-tooltip-${props.id}`}
                data-tooltip-content="Click to delete record"
            />

            <PrimaryButton
                data-tooltip-id={`update-help-tooltip-${props.id}`}
                data-tooltip-content="Click to update record"
                className="update btn"
                type="submit"
            >UPDATE</PrimaryButton>

            <Tooltip
                id={`update-help-tooltip-${props.id}`}
                float={true}
            />

        </form>
    )
}


export default Form