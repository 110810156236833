const COLLECTION = {
    SUPER_ADMINS: 'super_admins',
    TWILIO_ROOMS: 'twilio_rooms',
    ACCOUNTS: 'accounts',
    HEALTH_CARE_PROFESSIONALS: 'health_care_professionals',
    PATIENTS: 'patients',
    APPOINTMENTS: 'appointments',
    COUNTERS: 'counters',
    FEEDBACK: 'feedback',
    CHAT_CHANNELS: 'chat_channels',
    CHAT_THREAD: 'chat_thread',
    HELP: 'help',
    USEFUL_INFORMATION: 'useful_information'
}


export default COLLECTION