const theme = {
    grayBase: "#000",
    grayDarker: "#222",
    grayDark: "#333",
    gray: "#555",
    grayLight: "#777",
    grayLighter: "#eee",
    brandPrimary: "#D3007D",
    brandPrimaryDark: "#a0005f",
    brandSecondary: "#512B78",
    brandSuccess: "#5cb85c",
    brandInfo: "#5bc0de",
    brandWarning: "#f0ad4e",
    brandDanger: "#d9534f",
    fontFamily: `"Open Sans", Helvetica, Arial, sans-serif`
}

export default theme