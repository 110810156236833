import Patients from '../../ui/Patients'
import {connect} from 'react-redux'
import {addAccountFilter, addQuery, deleteItem, getPatients, setItem} from '../../../actions/patients'
import {addError} from '../../../actions/error'
import {getOptions} from "./selectors"
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        docs: state.patients.docs,
        options: getOptions(state),
        fetching: state.patients.fetching,
        queryString: state.patients.queryString,
        total_number_of_patients: state.patients.total_number_of_patients,
        account_id: state.patients.account_id,
        nextAvailable: state.patients.nextAvailable,
        prevAvailable: state.patients.prevAvailable,
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({
        deleteItem(doc) {
            dispatch(
                deleteItem(doc)
            )
        },
        setItem(doc) {
            dispatch(
                setItem(doc)
            )
        },
        addAccountFilter(account_id) {
            dispatch(
                addAccountFilter(account_id)
            )
        },
        addQuery(query) {
            dispatch(
                addQuery(query)
            )
        },
        addError(error) {
            dispatch(
                addError(error)
            )
        },
        getPatients(loadNext, loadPrev) {
            dispatch(
                getPatients(loadNext, loadPrev)
            )
        }
    })

export default connect(mapStateToProps, mapDispatchToProps)(Patients)  
