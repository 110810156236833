import theme from "../components/ui/Styled/theme"


const colourStyles = {
    control: (styles, {data, isDisabled, isFocused, isSelected}) => ({
        ...styles,
        backgroundColor: isDisabled ? '#f2f2f2' : 'white',
        borderColor: isDisabled ? '#e6e6e6' : '#e6e6e6',
        cursor: isDisabled ? 'not-allowed' : 'default'
    }),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => ({
        ...styles,
        backgroundColor: isDisabled ? null : isSelected ? theme.brandPrimary : isFocused ? theme.brandPrimary : null,
        color: isDisabled ? '#ccc' : isSelected ? 'white' : isFocused ? "white" : "#495057",
        cursor: 'pointer'
    }),
    multiValue: (styles, {data}) => ({
        ...styles,
        backgroundColor: theme.brandPrimary
    }),
    multiValueLabel: (styles, {data}) => ({
        ...styles,
        color: 'white'
    }),
    multiValueRemove: (styles, {data}) => ({
        ...styles,
        color: 'white',
        ':hover': {
            backgroundColor: data.color,
            color: 'white',
            cursor: 'pointer'
        },
    }),
}


export default colourStyles