import styled from "styled-components"
import theme from "./theme"


const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-out;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${props => props.show ? '0' : '100%'};
  will-change: opacity;
  top: 0;
  right: 0;
  z-index: 999;
`

Backdrop.defaultProps = {
    theme: theme
}

export default Backdrop