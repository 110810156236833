import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import trashIcon from '../../../assets/svg/trash.svg'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/HealthcareProfessionals/Add.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Select from "react-select"
import ResetButton from "../Styled/ResetButton"
import {Controller, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import isEmailValid from "../../../util/isEmailValid"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import colourStyles from "../../../constants/colourStyles"
import PrimaryButton from "../Styled/PrimaryButton"
import getPassword from "../../../util/getPassword"
import VersionNumber from "../Styled/VersionNumber"
import {logEvent} from "firebase/analytics"


const Add = ({options, addItem, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Add Healthcare Professional"
    })

    const navigate = useNavigate()

    const {register, handleSubmit, setValue, watch, reset, formState: {errors}, control} = useForm({
        mode: 'onSubmit',
        defaultValues: {
            account_id: null,
            hcp_id: "",
            first_name: "",
            last_name: "",
            email: "",
            title: "",
            hcp_type: "",
            password: getPassword()
        }
    })


    const onSubmit = data => {
        data.account_id = data.account_id.value

        addItem(data)

        navigate('/healthcare-professionals')
    }

    const [hcp_id, first_name, last_name, email, title, hcp_type, password] = watch(["hcp_id", "first_name", "last_name", "email", "title", "hcp_type", "password"])

    return (
        <main className="app add-healthcare-professional">
            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Add Healthcare Professional"/>

                <section className="content">

                    <h3>New Healthcare Professional</h3>
                    <Link
                        to="/healthcare-professionals"
                        className="close"
                        data-tooltip-id="add-hcp-tooltip"
                        data-tooltip-content="Click to close">
                        <img
                            src={close}
                            alt=""
                        />
                    </Link>

                    <form
                        className="add-healthcare-professional-form"
                        onSubmit={handleSubmit(onSubmit)}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                        }}
                    >
                        <div className="account_id">
                            <Label
                                data-tooltip-id="add-hcp-tooltip"
                                data-tooltip-content="The account ID for the item">
                                Account ID
                            </Label>
                            <Controller
                                render={({field: {onChange, value, name, ref}}) => (
                                    <Select
                                        value={value}
                                        name={name}
                                        options={options}
                                        onChange={onChange}
                                        className="react-select-field"
                                        placeholder="Please select an account..."
                                        styles={colourStyles}
                                        ref={ref}
                                    />
                                )}
                                control={control}
                                rules={{required: true}}
                                name="account_id"
                            />
                            {errors.account_id && <InvalidFeedback>The `Account ID` field is required</InvalidFeedback>}
                        </div>

                        <div className="hcp_id">
                            <Label
                                data-tooltip-id="add-hcp-tooltip"
                                data-tooltip-content="Healthcare Professional ID">
                                Healthcare Professional ID
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.hcp_id}
                                    type="text"
                                    placeholder="Please enter a healthcare professional ID..."
                                    {...register(
                                        'hcp_id',
                                        {
                                            required: true,
                                            validate: {
                                                lowerCase: value => value.toLowerCase() === value,
                                                noSpaces: value => value.replace(" ", "") === value,
                                                longerThanThreeLetters: value => value.length > 2
                                            }
                                        })
                                    }
                                />
                                {hcp_id.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('hcp_id', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.hcp_id && errors.hcp_id.type === 'required' &&
                                <InvalidFeedback>The `Healthcare Professional ID` field is required</InvalidFeedback>
                            }
                            {errors.hcp_id && errors.hcp_id.type === 'lowerCase' &&
                                <InvalidFeedback>The `Healthcare Professional ID` needs to be in
                                    lowercase</InvalidFeedback>
                            }
                            {errors.hcp_id && errors.hcp_id.type === 'noSpaces' &&
                                <InvalidFeedback>The `Healthcare Professional ID` cannot have spaces</InvalidFeedback>
                            }
                            {errors.hcp_id && errors.hcp_id.type === 'longerThanThreeLetters' &&
                                <InvalidFeedback>The `Healthcare Professional ID` must be at least 3
                                    characters</InvalidFeedback>
                            }
                        </div>

                        <div className="password">
                            <Label
                                data-tooltip-id="add-hcp-tooltip"
                                data-tooltip-content="Password for HCP ID">
                                Password
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    autoComplete="off"
                                    type="text"
                                    isValid={!!errors.password}
                                    placeholder="Please enter a password..."
                                    {...register(
                                        'password',
                                        {
                                            required: true,
                                            validate: {
                                                lowerCase: value => value.toLowerCase() === value,
                                                noSpaces: value => value.replace(" ", "") === value,
                                                longerThanThreeLetters: value => value.length > 2
                                            }
                                        })
                                    }
                                />
                                {password.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('password', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.password && errors.password.type === 'required' &&
                                <InvalidFeedback>The `Password` field is required</InvalidFeedback>
                            }
                            {errors.password && errors.password.type === 'lowerCase' &&
                                <InvalidFeedback>The password needs to be in lowercase</InvalidFeedback>
                            }
                            {errors.password && errors.password.type === 'noSpaces' &&
                                <InvalidFeedback>The password cannot have spaces</InvalidFeedback>
                            }
                            {errors.password && errors.password.type === 'longerThanThreeLetters' &&
                                <InvalidFeedback>The password must be at least 3 characters</InvalidFeedback>
                            }
                        </div>

                        <div className="hcp_type">
                            <Label
                                data-tooltip-id="add-hcp-tooltip"
                                data-tooltip-content="Type for the Healthcare Professional">
                                Type
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.hcp_type}
                                    type="text"
                                    placeholder="Please enter a type..."
                                    {...register(
                                        'hcp_type',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {hcp_type.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('hcp_type', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.hcp_type && <InvalidFeedback>The `Type` field is required</InvalidFeedback>}

                        </div>

                        <div className="title">
                            <Label
                                data-tooltip-id="add-hcp-tooltip"
                                data-tooltip-content="Title for the Healthcare Professional">
                                Title
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.title}
                                    type="text"
                                    placeholder="Please enter a title..."
                                    {...register(
                                        'title',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {title.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('title', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.title && <InvalidFeedback>The `Title` field is required</InvalidFeedback>}
                        </div>

                        <div className="first_name">
                            <Label
                                data-tooltip-id="add-hcp-tooltip"
                                data-tooltip-content="First name for Healthcare Professional">
                                First name
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.first_name}
                                    type="text"
                                    placeholder="Please enter a first name..."
                                    {...register(
                                        'first_name',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {first_name.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('first_name', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.first_name && <InvalidFeedback>The `First Name` field is required</InvalidFeedback>}
                        </div>

                        <div className="last_name">
                            <Label
                                data-tooltip-id="add-hcp-tooltip"
                                data-tooltip-content="Last name for Healthcare Professional">
                                Last name
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.last_name}
                                    type="text"
                                    placeholder="Please enter a last name..."
                                    {...register(
                                        'last_name',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {last_name.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('last_name', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.last_name && <InvalidFeedback>The `Last Name` field is required</InvalidFeedback>}
                        </div>

                        <div className="email">
                            <Label
                                data-tooltip-id="add-hcp-tooltip"
                                data-tooltip-content="Email for the Healthcare Professional">
                                Email
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.email}
                                    type="text"
                                    placeholder="Please enter an email..."
                                    {...register(
                                        'email',
                                        {
                                            required: true,
                                            validate: {
                                                isValidEmail: value => isEmailValid(value)
                                            }
                                        })
                                    }
                                />
                                {email.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('email', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.email && errors.email.type === 'required' &&
                                <InvalidFeedback>The `Email` field is required</InvalidFeedback>
                            }
                            {errors.email && errors.email.type === 'isValidEmail' &&
                                <InvalidFeedback>Please enter a valid email</InvalidFeedback>
                            }

                        </div>

                        <input
                            className="reset"
                            onClick={() => reset()}
                            type="image"
                            alt=""
                            src={trashIcon}
                            data-tooltip-id="add-hcp-tooltip"
                            data-tooltip-content="Click to reset record"
                        />

                        <PrimaryButton
                            data-tooltip-id="add-hcp-tooltip"
                            data-tooltip-content="Click to add record"
                            className="add btn"
                            type="submit"
                        >ADD HEALTHCARE PROFESSIONAL</PrimaryButton>

                        <Tooltip
                            id="add-hcp-tooltip"
                            float={true}
                        />

                    </form>

                </section>

            </article>
            <VersionNumber/>
        </main>
    )
}


export default Add