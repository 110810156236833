import React from 'react'
import styled, {keyframes} from 'styled-components'
import theme from "../Styled/theme"

const increase = keyframes`
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
`
const decrease = keyframes`
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
`

const ProgressBarIndeterminateWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 5px;
  overflow-x: hidden;
  z-index: 9999;

  .line {
    position: absolute;
    opacity: 0.4;
    background: #D3007D;
    width: 150%;
    height: 5px;
  }

  .sub-line {
    position: absolute;
    background: #D3007D;
    height: 5px;
  }

  .inc {
    animation: ${increase} 2s infinite;
  }

  .dec {
    animation: ${decrease} 2s 0.5s infinite;
  }
`

ProgressBarIndeterminateWrapper.defaultProps = {
    theme: theme
}

const ProgressBarIndeterminate = () =>
    <ProgressBarIndeterminateWrapper>
        <div className="line"/>
        <div className="sub-line inc"/>
        <div className="sub-line dec"/>
    </ProgressBarIndeterminateWrapper>


export default ProgressBarIndeterminate