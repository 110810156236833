import Lost from '../../ui/Lost'
import {connect} from 'react-redux'
import {addError} from '../../../actions/error'
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({
        addError(error) {
            dispatch(
                addError(error)
            )
        }
    })


export default connect(mapStateToProps, mapDispatchToProps)(Lost)