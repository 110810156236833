import ACTION from '../../constants/actionType'
import {auth} from "../index"
import {signOut} from 'firebase/auth'
import {addError} from "../error"


const isFetching = (fetching = false) =>
    ({
        type: ACTION.AUTH_FETCHING,
        payload: fetching
    })


export const signOutUser = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        await signOut(auth)
    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}