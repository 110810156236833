import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import trashIcon from '../../../assets/svg/trash.svg'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/UsefulInformation/Add.scss'
import getKeywords from "../../../util/getKeywords"
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import roleType from '../../../constants/roleType'
import Select from 'react-select'
import InputNumber from "rc-input-number"
import 'rc-input-number/assets/index.css'
import ResetButton from "../Styled/ResetButton"
import {Controller, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import isUrlValid from "../../../util/isUrlValid"
import isPhoneValid from "../../../util/isPhoneValid"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import TrashButton from "../Styled/TrashButton"
import colourStyles from "../../../constants/colourStyles"
import PrimaryButton from "../Styled/PrimaryButton"
import VersionNumber from "../Styled/VersionNumber"
import {logEvent} from "firebase/analytics"


const Add = ({options, addItem, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Add Useful Information"
    })

    const navigate = useNavigate()

    const {register, handleSubmit, setValue, watch, formState: {errors}, reset, control} = useForm({
        mode: 'onSubmit',
        defaultValues: {
            account_id: {label: 'All accounts', value: 'all'},
            title: "",
            url: "",
            group: {label: "All groups", value: "all"},
            index: 1
        }
    })

    const onSubmit = data => {
        data.account_id = data.account_id.value
        data.group = data.group.value
        data.keywords = getKeywords(data.title, data.url)

        addItem(data)

        navigate('/useful-information')
    }

    const [title, url] = watch(["title", "url"])

    return (
        <main className="app add-resource">
            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Add Useful Information"/>

                <section className="content">

                    <h3>New Useful Information</h3>

                    <Link
                        to="/useful-information"
                        className="close"
                        data-tooltip-id="add-useful-info-tooltip"
                        data-tooltip-content="Click to close">
                        <img
                            src={close}
                            alt="close"
                        />
                    </Link>

                    <form
                        className="add-useful-information-form"
                        onSubmit={handleSubmit(onSubmit)}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                        }}
                    >

                        <div className="account_id">
                            <Label
                                data-tooltip-id="add-useful-info-tooltip"
                                data-tooltip-content="The Account ID for the item">
                                Account ID
                            </Label>
                            <Controller
                                render={({field: {onChange, value, name, ref}}) => (
                                    <Select
                                        value={value}
                                        name={name}
                                        options={options}
                                        onChange={onChange}
                                        className="react-select-field"
                                        placeholder="Please select an account..."
                                        styles={colourStyles}
                                        ref={ref}
                                    />
                                )}
                                control={control}
                                rules={{required: true}}
                                name="account_id"
                            />
                            {errors.account_id && <InvalidFeedback>The `Account ID` field is required</InvalidFeedback>}
                        </div>

                        <div className="group">
                            <Label
                                data-tooltip-id="add-useful-info-tooltip"
                                data-tooltip-content="The group for the item">
                                Group
                            </Label>
                            <Controller
                                render={({field: {onChange, value, name, ref}}) => (
                                    <Select
                                        value={value}
                                        name={name}
                                        options={roleType}
                                        onChange={onChange}
                                        className="react-select-field"
                                        placeholder="Please select a group..."
                                        styles={colourStyles}
                                        ref={ref}
                                    />
                                )}
                                control={control}
                                rules={{required: true}}
                                name="group"
                            />
                        </div>

                        <div className="title">
                            <Label
                                data-tooltip-id="add-useful-info-tooltip"
                                data-tooltip-content="The title for the item">
                                Title
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.title}
                                    type="text"
                                    placeholder="Please enter a title..."
                                    {...register(
                                        'title',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {title.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('title', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.title && <InvalidFeedback>The `Title` field is required</InvalidFeedback>}
                        </div>

                        <div className="url">
                            <Label
                                data-tooltip-id="add-useful-info-tooltip"
                                data-tooltip-content="The url or telephone for the item">
                                URL
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.url}
                                    type="text"
                                    placeholder="Please enter a url or telephone..."
                                    {...register(
                                        'url',
                                        {
                                            required: true,
                                            validate: {
                                                isValidUrlOrPhone: value => isUrlValid(value) || isPhoneValid(value)
                                            }
                                        })
                                    }
                                />
                                {url.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('url', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.url && errors.url.type === "required" &&
                                <InvalidFeedback>The `URL` field is required</InvalidFeedback>
                            }
                            {errors.url && errors.url.type === 'isValidUrlOrPhone' &&
                                <InvalidFeedback>Please enter a valid url</InvalidFeedback>
                            }

                        </div>

                        <div className="index">
                            <Label
                                data-tooltip-id="add-useful-info-tooltip"
                                data-tooltip-content="The rank order for the item">
                                Order
                            </Label>
                            <Controller
                                render={({field: {onChange, value, name, ref}}) => (
                                    <InputNumber
                                        name={name}
                                        min={1}
                                        value={value}
                                        onChange={onChange}
                                        ref={ref}
                                    />
                                )}
                                control={control}
                                rules={{required: true}}
                                name="index"
                            />
                        </div>

                        <TrashButton
                            className="reset"
                            onClick={() => reset()}
                            type="image"
                            src={trashIcon}
                            alt="delete icon"
                            data-tooltip-id="add-useful-info-tooltip"
                            data-tooltip-content="Click to reset record"
                        />

                        <PrimaryButton
                            data-tooltip-id="add-useful-info-tooltip"
                            data-tooltip-content="Click to add record"
                            className="add btn"
                            type="submit">
                            ADD USEFUL INFORMATION
                        </PrimaryButton>

                        <Tooltip
                            id="add-useful-info-tooltip"
                            float={true}
                        />
                    </form>

                </section>
            </article>
            <VersionNumber/>
        </main>
    )
}


export default Add