import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'

import PrivateRoute from '../components/container/PrivateRoute'

import Data from "../components/container/Data/Data"

import SuperAdmins from '../components/container/SuperAdmins/SuperAdmins'
import AddSuperAdmin from '../components/container/SuperAdmins/AddSuperAdmin'

import Appointments from '../components/container/Appointments/Appointments'

import Accounts from '../components/container/Accounts/Accounts'
import AddAccount from '../components/container/Accounts/AddAccount'

import HealthcareProfessionals from '../components/container/HealthcareProfessionals/HealthcareProfessionals'
import AddHealthcareProfessional from '../components/container/HealthcareProfessionals/AddHealthcareProfessional'

import Patients from '../components/container/Patients/Patients'
import AddPatient from '../components/container/Patients/AddPatient'

import Analytics from '../components/container/Analytics/Analytics'

import UsefulInformation from '../components/container/UsefulInformation/UsefulInformation'
import AddUsefulInformation from '../components/container/UsefulInformation/AddUsefulInformation'

import Help from '../components/container/Help/Help'
import AddHelp from '../components/container/Help/AddHelp'

import Login from '../components/container/Login/Login'
import Lost from '../components/container/Lost/Lost'


const routes = (
    <BrowserRouter>
        <Routes>

            <Route path="/login" element={
                <Login/>
            }/>

            <Route path="/data" element={
                <PrivateRoute>
                    <Data/>
                </PrivateRoute>
            }/>

            <Route path="/admins" element={
                <PrivateRoute>
                    <SuperAdmins/>
                </PrivateRoute>
            }/>

            <Route path="/admins/add" element={
                <PrivateRoute>
                    <AddSuperAdmin/>
                </PrivateRoute>
            }/>

            <Route path="/accounts" element={
                <PrivateRoute>
                    <Accounts/>
                </PrivateRoute>
            }/>

            <Route path="/accounts/add" element={
                <PrivateRoute>
                    <AddAccount/>
                </PrivateRoute>
            }/>

            <Route path="/appointments" element={
                <PrivateRoute>
                    <Appointments/>
                </PrivateRoute>
            }/>

            <Route path="/healthcare-professionals" element={
                <PrivateRoute>
                    <HealthcareProfessionals/>
                </PrivateRoute>
            }/>

            <Route path="/healthcare-professionals/add" element={
                <PrivateRoute>
                    <AddHealthcareProfessional/>
                </PrivateRoute>
            }/>

            <Route path="/patients" element={
                <PrivateRoute>
                    <Patients/>
                </PrivateRoute>
            }/>

            <Route path="/patients/add" element={
                <PrivateRoute>
                    <AddPatient/>
                </PrivateRoute>
            }/>

            <Route path="/analytics" element={
                <PrivateRoute>
                    <Analytics/>
                </PrivateRoute>
            }/>

            <Route path="/useful-information" element={
                <PrivateRoute>
                    <UsefulInformation/>
                </PrivateRoute>
            }/>

            <Route path="/useful-information/add" element={
                <PrivateRoute>
                    <AddUsefulInformation/>
                </PrivateRoute>
            }/>

            <Route path="/help" element={
                <PrivateRoute>
                    <Help/>
                </PrivateRoute>
            }/>

            <Route path="/help/add" element={
                <PrivateRoute>
                    <AddHelp/>
                </PrivateRoute>
            }/>

            <Route path="*" element={
                <PrivateRoute>
                    <Lost/>
                </PrivateRoute>
            }/>

        </Routes>
    </BrowserRouter>
)


export default routes 