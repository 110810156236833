import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import trashIcon from '../../../assets/svg/trash.svg'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/Patients/Add.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Select from "react-select"
import ResetButton from "../Styled/ResetButton"
import {Controller, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import TrashButton from "../Styled/TrashButton"
import colourStyles from "../../../constants/colourStyles"
import PrimaryButton from "../Styled/PrimaryButton"
import VersionNumber from "../Styled/VersionNumber"
import {logEvent} from "firebase/analytics"


const Add = ({options, addItem, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Add Patient"
    })

    const navigate = useNavigate()

    const {register, handleSubmit, setValue, watch, formState: {errors}, control, reset} = useForm({
        mode: 'onSubmit',
        defaultValues: {
            account_id: {label: "All accounts", value: "all"},
            patient_id: "",
            password: "20home19!"
        }
    })

    const [patient_id, password] = watch(["patient_id", "password"])

    const onSubmit = data => {
        data.account_id = data.account_id.value

        addItem(data)

        navigate('/patients')
    }


    return (
        <main className="app add-patient">
            <ShowErrors/>
            <Header/>
            <article className="inner-content">

                <EntryHeader title="Add Patient"/>

                <section className="content">

                    <h3>New Patient</h3>

                    <Link
                        to="/patients"
                        className="close"
                        data-tooltip-id="add-patient-tooltip"
                        data-tooltip-content="Click to close">
                        <img
                            alt="close icon"
                            src={close}/>
                    </Link>

                    <form
                        className="add-patient-form"
                        onSubmit={handleSubmit(onSubmit)}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                        }}
                    >

                        <div className="account_id">
                            <Label
                                data-tooltip-id="add-patient-tooltip"
                                data-tooltip-content="The Account ID for the patient">
                                Account ID
                            </Label>
                            <Controller
                                render={({field: {onChange, value, name, ref}}) => (
                                    <Select
                                        value={value}
                                        name={name}
                                        options={options}
                                        onChange={onChange}
                                        className="react-select-field"
                                        placeholder="Please select an account..."
                                        styles={colourStyles}
                                        ref={ref}
                                    />
                                )}
                                control={control}
                                rules={{required: true}}
                                name="account_id"
                            />
                            {errors.account_id && <InvalidFeedback>The `Account ID` field is required</InvalidFeedback>}
                        </div>

                        <div className="patient_id">
                            <Label
                                data-tooltip-id="add-patient-tooltip"
                                data-tooltip-content="Patient ID">
                                Patient ID
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    tabIndex="10"
                                    isValid={!!errors.patient_id}
                                    type="text"
                                    placeholder="Please enter a patient ID..."
                                    {...register(
                                        'patient_id',
                                        {
                                            required: true,
                                            validate: {
                                                lowerCase: value => value.toLowerCase() === value,
                                                noSpaces: value => value.replace(" ", "") === value,
                                                longerThanThreeLetters: value => value.length > 2
                                            }
                                        })
                                    }
                                />
                                {patient_id.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('patient_id', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.patient_id && errors.patient_id.type === 'required' &&
                                <InvalidFeedback>The `Patient ID` field is required</InvalidFeedback>
                            }
                            {errors.patient_id && errors.patient_id.type === 'lowerCase' &&
                                <InvalidFeedback>The `Patient ID` needs to be in lowercase</InvalidFeedback>
                            }
                            {errors.patient_id && errors.patient_id.type === 'noSpaces' &&
                                <InvalidFeedback>The `Patient ID` cannot have spaces</InvalidFeedback>
                            }
                            {errors.patient_id && errors.patient_id.type === 'longerThanThreeLetters' &&
                                <InvalidFeedback>The `Patient ID` must be at least 3 characters</InvalidFeedback>
                            }

                        </div>

                        <div className="password">
                            <Label
                                data-tooltip-id="add-patient-tooltip"
                                data-tooltip-content="Password for patient">
                                Password
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    autoComplete="off"
                                    isValid={!!errors.password}
                                    type="text"
                                    placeholder="Please enter a password..."
                                    {...register(
                                        'password',
                                        {
                                            required: true,
                                            validate: {
                                                lowerCase: value => value.toLowerCase() === value,
                                                noSpaces: value => value.replace(" ", "") === value,
                                                longerThanThreeLetters: value => value.length > 2
                                            }
                                        })
                                    }
                                />
                                {password.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('password', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.password && errors.password.type === 'required' &&
                                <InvalidFeedback>The `Password` field is required</InvalidFeedback>
                            }
                            {errors.password && errors.password.type === 'lowerCase' &&
                                <InvalidFeedback>The password needs to be in lowercase</InvalidFeedback>
                            }
                            {errors.password && errors.password.type === 'noSpaces' &&
                                <InvalidFeedback>The password cannot have spaces</InvalidFeedback>
                            }
                            {errors.password && errors.password.type === 'longerThanThreeLetters' &&
                                <InvalidFeedback>The password must be at least 3 characters</InvalidFeedback>
                            }
                        </div>

                        <TrashButton
                            tabIndex="30"
                            className="reset"
                            onClick={() => reset()}
                            type="image"
                            alt="reset form entries"
                            src={trashIcon}
                            data-tooltip-id="add-patient-tooltip"
                            data-tooltip-content="Click to reset record"
                        />

                        <PrimaryButton
                            tabIndex="40"
                            data-tooltip-id="add-patient-tooltip"
                            data-tooltip-content="Click to add record"
                            className="add btn"
                            type="submit"
                        >ADD PATIENT</PrimaryButton>

                        <Tooltip
                            id="add-patient-tooltip"
                            float={true}
                        />
                    </form>

                </section>
            </article>
            <VersionNumber/>
        </main>
    )

}

export default Add