import React from 'react'
import {Link} from 'react-router-dom'
import '../../assets/scss/QuickSearchWithAccountFilter.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Select from 'react-select'
import ResetButton from "./Styled/ResetButton"
import colourStyles from "../../constants/colourStyles"


const QuickSearchWithAccountFilter = ({
                                          query,
                                          setQuery,
                                          accountFilter,
                                          setAccountFilter,
                                          options,
                                          entries,
                                          linkTo,
                                          linkTitle
                                      }) => {

    return (
        <section className="quick-search">
            <form onSubmit={e => e.preventDefault()}>
                <div className="quick-search-container">
                    <input
                        type="text"
                        placeholder="Quick search..."
                        value={query}
                        onChange={e => {
                            setQuery(e.target.value)
                        }}
                    />
                    {query.length > 0 &&
                        <ResetButton onClick={() => setQuery("")} type="reset"/>
                    }
                </div>

                <Select
                    id="filter-by-account-id"
                    className="react-select-field"
                    name="filter"
                    placeholder="Please select account..."
                    value={options.find(i => i.value === accountFilter)}
                    onChange={selected => {
                        setAccountFilter(selected.value)
                    }}
                    options={options}
                    styles={colourStyles}
                />

                {entries > 0 &&
                    <p>{entries} entries</p>
                }
            </form>

            <Link
                data-cy="link-add"
                to={linkTo}
                className="btn"
                data-tooltip-id="quick-search-with-account-filter-tooltip"
                data-tooltip-content="Click to add record">
                {linkTitle}
            </Link>
            <Tooltip
                id="quick-search-with-account-filter-tooltip"
                float={true}
            />
        </section>
    )

}


export default QuickSearchWithAccountFilter