import appReducer from './reducers'
import {composeWithDevTools} from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import {applyMiddleware, legacy_createStore as createStore} from 'redux'


export const configureStore = () => window.location.hostname === "127.0.0.1" ?
    createStore(
        appReducer,
        composeWithDevTools(
            applyMiddleware(thunk)
        )
    ) :
    createStore(
        appReducer,
        applyMiddleware(thunk)
    )