import React from 'react'
import styled from "styled-components"
import XlsxPopulate from 'xlsx-populate'


const ButtonWrapper = styled.button`
  background-color: #D3007D;
  color: white;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  padding: 10px 25px;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  margin-left: auto;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #a0005f;
  }
`

const Button = ({data}) => {

    const download = (data, filename, type) => {
        const file = new Blob([data], {type: type})
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file, filename)
        } else {
            const a = document.createElement("a")
            const url = URL.createObjectURL(file)
            a.href = url
            a.download = filename
            document.body.appendChild(a)
            a.click()
            setTimeout(() => {
                document.body.removeChild(a)
                window.URL.revokeObjectURL(url)
            }, 0)
        }
    }

    const date = () => (new Date()).toISOString().slice(0, 19).replace(/-/g, "-").replace("T", "-").replace(":", "-")

    const handleClick = async () => {
        if (data.length > 1) {
            const workbook = await XlsxPopulate.fromBlankAsync()

            workbook.sheet(0).cell("A1").value(data)

            const buffer = await workbook.outputAsync({password: "NUTUK"})

            download(buffer, `analytics-data-${date()}.xlsx`, `application/vnd.ms-excel`)
        }
    }

    return (
        <ButtonWrapper
            disabled={data.length < 2}
            data-tooltip-id="analytics-tooltip"
            data-tooltip-content="Click to download feedback data in excel format"
            onClick={() => handleClick()}
        >DOWNLOAD EXCEL</ButtonWrapper>
    )

}

export default Button
