import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {collection, onSnapshot} from "firebase/firestore"
import _ from "lodash"
import {batch} from "react-redux"
import {addError} from "../error"


const addItem = item =>
    ({
        type: ACTION.SUPER_ADMIN_NEW_DOC,
        payload: item
    })

const updateItem = item =>
    ({
        type: ACTION.SUPER_ADMIN_UPDATE_DOC,
        payload: item
    })

const deleteItem = item =>
    ({
        type: ACTION.SUPER_ADMIN_DELETE_DOC,
        payload: item
    })


export const superAdminsDocListener = () => async (dispatch, getState) => {

    if (_.isFunction(window.superAdminsDocListener)) {
        window.superAdminsDocListener()
        delete window.superAdminsDocListener
    }

    const superAdminCollRef = collection(db, COLLECTION.SUPER_ADMINS)

    window.superAdminsDocListener = onSnapshot(superAdminCollRef, querySnapshot => {
        batch(() => {
            querySnapshot.docChanges().forEach(change => {
                const item = {
                    id: change.doc.id,
                    ...change.doc.data()
                }

                if (_.has(item, "registered")) {
                    item.registered = item.registered.toMillis()
                }

                switch (change.type) {
                    case 'added':
                        dispatch(
                            addItem(item)
                        )
                        break
                    case 'modified':
                        dispatch(
                            updateItem(item)
                        )
                        break
                    case 'removed':
                        dispatch(
                            deleteItem(item)
                        )
                        break
                    default:
                        break
                }

            })
        })
    }, error => {
        dispatch(
            addError(error.message)
        )
    })
}