import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {collection, onSnapshot} from "firebase/firestore"
import _ from "lodash"
import {batch} from "react-redux"
import {addError} from "../error"


const addItem = item =>
    ({
        type: ACTION.HELP_NEW_DOC,
        payload: item
    })

const updateItem = item =>
    ({
        type: ACTION.HELP_UPDATE_DOC,
        payload: item
    })

const deleteItem = item =>
    ({
        type: ACTION.HELP_DELETE_DOC,
        payload: item
    })


export const helpDocsListener = () => async (dispatch, getState) => {

    if (_.isFunction(window.helpDocsListener)) {
        window.helpDocsListener()
        delete window.helpDocsListener
    }

    const helpCollRef = collection(db, COLLECTION.HELP)

    window.helpDocsListener = onSnapshot(helpCollRef, querySnapshot => {
        batch(() => {
            querySnapshot.docChanges().forEach(change => {
                const item = {
                    id: change.doc.id,
                    ...change.doc.data()
                }

                switch (change.type) {
                    case 'added':
                        dispatch(
                            addItem(item)
                        )
                        break
                    case 'modified':
                        dispatch(
                            updateItem(item)
                        )
                        break
                    case 'removed':
                        dispatch(
                            deleteItem(item)
                        )
                        break
                    default:
                        break
                }
            })
        })
    }, error => {
        dispatch(
            addError(error.message)
        )
    })
}