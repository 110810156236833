import React from 'react'
import '../../../assets/scss/Accounts/Form.scss'
import trashIcon from '../../../assets/svg/trash.svg'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import ResetButton from "../Styled/ResetButton"
import {useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import TrashButton from "../Styled/TrashButton"
import PrimaryButton from "../Styled/PrimaryButton"


const Form = props => {

    const {register, handleSubmit, setValue, watch, formState: {errors}} = useForm({mode: 'onSubmit'})

    const onSubmit = data => {
        data.id = props.id

        props.handleUpdate(data)
    }

    const display_name = watch("display_name", props.display_name)

    return (
        <form
            className="edit-account"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault()
            }}
        >

            <div className="account_id">
                <Label
                    data-tooltip-id={`update-account-tooltip-${props.id}`}
                    data-tooltip-content="The account id for the item">
                    Account ID
                </Label>
                <InputField
                    tabIndex="10"
                    isValid={!!errors.account_id}
                    name="account_id"
                    type="text"
                    placeholder="Please enter a Account ID..."
                    defaultValue={props.account_id}
                    {...register(
                        'account_id',
                        {
                            required: true,
                            validate: {
                                lowerCase: value => value.toLowerCase() === value,
                                noSpaces: value => value.replace(" ", "") === value,
                                longerThanThreeLetters: value => value.length > 2
                            }
                        })
                    }
                    readOnly
                />
            </div>

            <div className="display_name">
                <Label
                    data-tooltip-id={`update-account-tooltip-${props.id}`}
                    data-tooltip-content="The account name to display">
                    Display Name
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        tabIndex="20"
                        isValid={!!errors.display_name}
                        type="text"
                        placeholder="Please enter an account name..."
                        defaultValue={props.display_name}
                        {...register(
                            'display_name',
                            {
                                required: true
                            })
                        }
                    />
                    {display_name.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('display_name', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.display_name && <InvalidFeedback>The `Display Name` field is required</InvalidFeedback>}
            </div>

            <TrashButton
                className="trash"
                onClick={e => {
                    e.preventDefault()
                    props.handleDelete(props.id)
                }}
                type="image"
                alt="delete item"
                src={trashIcon}
                data-tooltip-id={`update-account-tooltip-${props.id}`}
                data-tooltip-content="Click to delete record"
            />

            <PrimaryButton
                data-tooltip-id={`update-account-tooltip-${props.id}`}
                data-tooltip-content="Click to update record"
                className="update btn"
                type="submit"
            >UPDATE</PrimaryButton>

            <Tooltip
                id={`update-account-tooltip-${props.id}`}
                float={true}
            />

        </form>
    )
}


export default Form