import React from 'react'
import '../../../assets/scss/Patients/Form.scss'
import trashIcon from '../../../assets/svg/trash.svg'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Select from "react-select"
import ResetButton from "../Styled/ResetButton"
import {Controller, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import TrashButton from "../Styled/TrashButton"
import colourStyles from "../../../constants/colourStyles"
import PrimaryButton from "../Styled/PrimaryButton"


const Form = props => {

    const {register, handleSubmit, setValue, watch, formState: {errors}, control} = useForm({mode: 'onSubmit'})

    const password = watch("password", props.password)

    const onSubmit = data => {
        data.id = props.id
        data.account_id = data.account_id.value

        props.handleUpdate(data)
    }


    return (
        <form
            className="edit-patient-form"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault()
            }}
        >

            <div className="account_id">
                <Label
                    data-tooltip-id={`update-patient-tooltip-${props.id}`}
                    data-tooltip-content="The Account ID for the patient">
                    Account ID
                </Label>
                <Controller
                    render={({field: {onChange, value, name, ref}}) => (
                        <Select
                            value={value}
                            name={name}
                            maxMenuHeight={170}
                            options={props.options}
                            onChange={onChange}
                            className="react-select-field"
                            placeholder="Please select an account..."
                            styles={colourStyles}
                            ref={ref}
                        />
                    )}
                    defaultValue={props.options.find(account => account.value === props.account_id)}
                    control={control}
                    rules={{required: true}}
                    name="account_id"
                />
                {errors.account_id && <InvalidFeedback>The `Account ID` field is required</InvalidFeedback>}
            </div>

            <div className="patient_id">
                <Label
                    data-tooltip-id={`update-patient-tooltip-${props.id}`}
                    data-tooltip-content="Patient ID">
                    Patient ID
                </Label>
                <InputField
                    isValid={!!errors.patient_id}
                    type="text"
                    placeholder="Please enter a Patient ID..."
                    defaultValue={props.patient_id}
                    {...register(
                        'patient_id',
                        {
                            required: true,
                            validate: {
                                lowerCase: value => value.toLowerCase() === value,
                                noSpaces: value => value.replace(" ", "") === value,
                                longerThanThreeLetters: value => value.length > 2
                            }
                        })
                    }
                    readOnly
                />
            </div>

            <div className="password">
                <Label
                    data-tooltip-id={`update-patient-tooltip-${props.id}`}
                    data-tooltip-content="Password for patient">
                    Password
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.password}
                        type="text"
                        placeholder="Please enter the password..."
                        defaultValue={props.password}
                        {...register(
                            'password',
                            {
                                required: true,
                                validate: {
                                    lowerCase: value => value.toLowerCase() === value,
                                    noSpaces: value => value.replace(" ", "") === value,
                                    longerThanThreeLetters: value => value.length > 2
                                }
                            })
                        }
                    />
                    {password.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('password', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.password && errors.password.type === 'required' &&
                    <InvalidFeedback>The `Password` field is required</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'lowerCase' &&
                    <InvalidFeedback>The password needs to be in lowercase</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'noSpaces' &&
                    <InvalidFeedback>The password cannot have spaces</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'longerThanThreeLetters' &&
                    <InvalidFeedback>The password must be at least 3 characters</InvalidFeedback>
                }
            </div>

            <TrashButton
                className="trash"
                alt="trash icon"
                onClick={e => {
                    e.preventDefault()
                    props.handleDelete(props.id)
                }}
                type="image"
                src={trashIcon}
                data-tooltip-id={`update-patient-tooltip-${props.id}`}
                data-tooltip-content="Click to delete record"
            />

            <PrimaryButton
                className="update btn"
                type="submit"
                data-tooltip-id={`update-patient-tooltip-${props.id}`}
                data-tooltip-content="Click to update record">
                UPDATE
            </PrimaryButton>

            <Tooltip
                id={`update-patient-tooltip-${props.id}`}
                float={true}
            />
        </form>
    )
}


export default Form