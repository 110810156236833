import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {collection, doc, getDocs, increment, query, setDoc, Timestamp, where} from "firebase/firestore"
import {addError} from "../error"
import Role from "../../constants/Role"
import getKeywords from "../../util/getKeywords"


const isFetching = fetching =>
    ({
        type: C.HEALTHCARE_PROFESSIONALS_FETCHING_DOCS,
        payload: fetching
    })

export const addItem = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        const healthCareProfessionalsCollRef = collection(db, COLLECTION.HEALTH_CARE_PROFESSIONALS)

        const q = query(healthCareProfessionalsCollRef, where("hcp_id", "==", item.hcp_id))

        const querySnapshot = await getDocs(q)

        if (!querySnapshot.empty) {
            throw new Error(`Healthcare Professional with id: ${item.hcp_id} already exists. Please use a unique id.`)
        }

        const temp = {
            hcp_id: item.hcp_id,
            account_id: item.account_id,
            first_name: item.first_name,
            last_name: item.last_name,
            email: item.email,
            title: item.title,
            hcp_type: item.hcp_type,
            password: item.password,
            registered: Timestamp.now(),
            online: {
                status: false,
                last_updated: Timestamp.now(),
            },
            is_auth: false,
            keywords: getKeywords(item.hcp_id, item.first_name, item.last_name, item.email, item.hcp_type)
        }

        const id = doc(healthCareProfessionalsCollRef).id + Role.HEALTH_CARE_PROFESSIONAL

        await setDoc(doc(db, COLLECTION.HEALTH_CARE_PROFESSIONALS, id), temp)

        const hcpCounterRef = doc(db, COLLECTION.COUNTERS, COLLECTION.HEALTH_CARE_PROFESSIONALS)

        await setDoc(hcpCounterRef, {total: increment(1)}, {merge: true})

    } catch (error) {

        dispatch(
            addError(error.message)
        )

    } finally {
        dispatch(
            isFetching(false)
        )
    }

}
