import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {deleteDoc, doc, increment, setDoc} from "firebase/firestore"
import {addError} from "../error"
import getKeywords from "../../util/getKeywords"


const isFetching = fetching =>
    ({
        type: ACTION.USEFUL_INFORMATION_FETCHING_DOCS,
        payload: fetching
    })

export const deleteItem = itemID => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        await deleteDoc(doc(db, COLLECTION.USEFUL_INFORMATION, itemID))

        const usefulInfoCounterRef = doc(db, COLLECTION.COUNTERS, COLLECTION.USEFUL_INFORMATION)

        await setDoc(usefulInfoCounterRef, {total: increment(-1)}, {merge: true})

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}

export const setItem = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {

        const temp = {
            account_id: item.account_id,
            title: item.title,
            index: item.index,
            url: item.url,
            group: item.group,
            keywords: getKeywords(item.title, item.url)
        }

        await setDoc(doc(db, COLLECTION.USEFUL_INFORMATION, item.id), temp, {merge: true})

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}