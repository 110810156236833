import ShowErrors from '../ui/Errors/ShowErrors'
import {clearError} from '../../actions/error'
import {connect} from 'react-redux'


const mapStateToProps = state => {
    return {
        errors: state.errors
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onClearError(index) {
            dispatch(
                clearError(index)
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowErrors)