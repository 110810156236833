import React from 'react'
import '../../../assets/scss/SuperAdmins/Form.scss'
import trashIcon from '../../../assets/svg/trash.svg'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import ResetButton from "../Styled/ResetButton"
import {useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import isEmailValid from "../../../util/isEmailValid"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import TrashButton from "../Styled/TrashButton"
import PrimaryButton from "../Styled/PrimaryButton"


const Form = props => {

    const {register, handleSubmit, setValue, watch, formState: {errors}} = useForm({mode: 'onSubmit'})

    const onSubmit = data => {
        data.id = props.id

        props.handleUpdate(data)
    }

    const password = watch("password", props.password ?? "")
    const first_name = watch("first_name", props.first_name ?? "")
    const last_name = watch("last_name", props.last_name ?? "")
    const email = watch("email", props.email ?? "")

    return (
        <form
            className="edit-admin"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault()
            }}
        >

            <div className="admin_id">
                <Label
                    data-tooltip-id={`update-admin-tooltip-${props.id}`}
                    data-tooltip-content="Admin's ID">
                    Admin ID
                </Label>
                <InputField
                    type="text"
                    placeholder="Please enter an admin ID..."
                    defaultValue={props.admin_id}
                    {...register(
                        'admin_id',
                        {
                            required: true,
                            validate: {
                                lowerCase: value => value.toLowerCase() === value,
                                noSpaces: value => value.replace(" ", "") === value,
                                longerThanThreeLetters: value => value.length > 2
                            }
                        })
                    }
                    readOnly
                />
            </div>

            <div className="password">
                <Label
                    data-tooltip-id={`update-admin-tooltip-${props.id}`}
                    data-tooltip-content="Admin's Password">
                    Password
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.password}
                        type="text"
                        placeholder="Please enter a password..."
                        defaultValue={props.password}
                        {...register(
                            'password',
                            {
                                required: true,
                                validate: {
                                    lowerCase: value => value.toLowerCase() === value,
                                    noSpaces: value => value.replace(" ", "") === value,
                                    longerThanThreeLetters: value => value.length > 2
                                }
                            })
                        }
                    />
                    {password.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('password', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.password && errors.password.type === 'required' &&
                    <InvalidFeedback>The `Password` field is required</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'lowerCase' &&
                    <InvalidFeedback>The password needs to be in lowercase</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'noSpaces' &&
                    <InvalidFeedback>The password cannot have spaces</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'longerThanThreeLetters' &&
                    <InvalidFeedback>The password must be at least 3 characters</InvalidFeedback>
                }
            </div>

            <div className="first_name">
                <Label
                    data-tooltip-id={`update-admin-tooltip-${props.id}`}
                    data-tooltip-content="Admin's First Name">
                    First Name
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.first_name}
                        type="text"
                        placeholder="Please enter a first name..."
                        defaultValue={props.first_name}
                        {...register(
                            'first_name',
                            {
                                required: true
                            })
                        }
                    />
                    {first_name.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('first_name', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.first_name && <InvalidFeedback>The `First Name` field is required</InvalidFeedback>}
            </div>

            <div className="last_name">
                <Label
                    data-tooltip-id={`update-admin-tooltip-${props.id}`}
                    data-tooltip-content="Admin's Last Name">
                    Last name
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.last_name}
                        type="text"
                        placeholder="Please enter a last name..."
                        defaultValue={props.last_name}
                        {...register(
                            'last_name',
                            {
                                required: true
                            })
                        }
                    />
                    {last_name.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('last_name', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.last_name && <InvalidFeedback>The `Last Name` field is required</InvalidFeedback>}
            </div>

            <div className="email">
                <Label
                    data-tooltip-id={`update-admin-tooltip-${props.id}`}
                    data-tooltip-content="Admin's Email">
                    Email
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.email}
                        type="text"
                        placeholder="Please enter an email..."
                        defaultValue={props.email}
                        {...register(
                            'email',
                            {
                                required: true,
                                validate: {
                                    isValidEmail: value => isEmailValid(value)
                                }
                            })
                        }
                    />
                    {email.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('email', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.email && errors.email.type === 'required' &&
                    <InvalidFeedback>The `Email` field is required</InvalidFeedback>
                }
                {errors.email && errors.email.type === 'isValidEmail' &&
                    <InvalidFeedback>Please enter a valid email</InvalidFeedback>
                }
            </div>

            <TrashButton
                className="trash"
                onClick={e => {
                    e.preventDefault()
                    props.handleDelete(props.id)
                }}
                type="image" src={trashIcon}
                alt="Trash Icon"
                data-tooltip-id={`update-admin-tooltip-${props.id}`}
                data-tooltip-content="Click to delete record"
            />

            <PrimaryButton
                className="update btn"
                type="submit"
                data-tooltip-id={`update-admin-tooltip-${props.id}`}
                data-tooltip-content="Click to update record">
                UPDATE
            </PrimaryButton>

            <Tooltip
                id={`update-admin-tooltip-${props.id}`}
                float={true}
            />

        </form>
    )
}


export default Form