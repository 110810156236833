import styled from 'styled-components'
import theme from "./theme"


const ResetButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 1rem;
  display: inline-block;
  background-color: transparent;
  vertical-align: middle;
  outline: 0;
  padding: 0;
  margin: 0;
  border: 0;

  &:after {
    content: " ";
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 19'%3E%3Cpath stroke='none' fill='%23C5C5C6' d='M 9.5,-0 C 4.25,-0 0,4.25 0,9.5 0,14.75 4.25,19 9.5,19 14.75,19 19,14.75 19,9.5 19,4.25 14.75,-0 9.5,-0 Z M 9.5,17.77 C 4.95,17.77 1.23,14.09 1.23,9.5 1.23,4.95 4.91,1.23 9.5,1.23 14.05,1.23 17.77,4.91 17.77,9.5 17.77,14.05 14.09,17.77 9.5,17.77 Z M 13.13,6.85 L 10.48,9.5 13.13,12.15 C 13.31,12.33 13.31,12.63 13.13,12.81 L 12.81,13.13 C 12.63,13.31 12.33,13.31 12.15,13.13 L 9.5,10.48 6.85,13.13 C 6.67,13.31 6.37,13.31 6.19,13.13 L 5.87,12.81 C 5.69,12.63 5.69,12.33 5.87,12.15 L 8.52,9.5 5.87,6.85 C 5.69,6.67 5.69,6.37 5.87,6.19 L 6.19,5.87 C 6.37,5.69 6.67,5.69 6.85,5.87 L 9.5,8.52 12.15,5.87 C 12.33,5.69 12.63,5.69 12.81,5.87 L 13.13,6.19 C 13.31,6.37 13.31,6.67 13.13,6.85 Z M 13.13,6.85' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    display: block;
    width: 1.9rem;
    height: 1.9rem;
    position: absolute;
    z-index: 99999999;
    right: 0;
    top: 0;
    cursor: pointer;
  }
`

ResetButton.defaultProps = {
    theme: theme
}

export default ResetButton