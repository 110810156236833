import ACTION from '../../../constants/actionType'
import {combineReducers} from 'redux'


const fetching = (state = false, action) =>
    (action.type === ACTION.AUTH_FETCHING) ?
        action.payload :
        state

const user = (state = null, action) => {

    switch (action.type) {
        case ACTION.AUTH_LOGIN:
            return action.payload
        case ACTION.AUTH_LOGOUT:
            return null
        default:
            return state
    }

}


export default combineReducers({
    user,
    fetching
})