import React from 'react'


const AddRecordIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
        <path fill='#fff'
              d='M 19.58,8.75 L 11.25,8.75 11.25,0.42 C 11.25,0.19 11.06,-0 10.83,-0 L 9.17,-0 C 8.94,-0 8.75,0.19 8.75,0.42 L 8.75,8.75 0.42,8.75 C 0.19,8.75 0,8.94 0,9.17 L 0,10.83 C 0,11.06 0.19,11.25 0.42,11.25 L 8.75,11.25 8.75,19.58 C 8.75,19.81 8.94,20 9.17,20 L 10.83,20 C 11.06,20 11.25,19.81 11.25,19.58 L 11.25,11.25 19.58,11.25 C 19.81,11.25 20,11.06 20,10.83 L 20,9.17 C 20,8.94 19.81,8.75 19.58,8.75 Z M 19.58,8.75'
        />
    </svg>
)

export default AddRecordIcon