import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {collection, onSnapshot, orderBy, query, where} from "firebase/firestore"
import _ from 'lodash'
import {batch} from "react-redux"
import {addError} from "../error"


const addItem = item =>
    ({
        type: C.HEALTHCARE_PROFESSIONALS_NEW_DOC,
        payload: item
    })

const updateItem = item =>
    ({
        type: C.HEALTHCARE_PROFESSIONALS_UPDATE_DOC,
        payload: item
    })

const deleteItem = item =>
    ({
        type: C.HEALTHCARE_PROFESSIONALS_DELETE_DOC,
        payload: item
    })


export const healthcareProfessionalsListener = (
    account_id = "all"
) => async (dispatch, getState) => {

    const hcpCollRef = collection(db, COLLECTION.HEALTH_CARE_PROFESSIONALS)

    const q = account_id === 'all' ? query(hcpCollRef, orderBy("registered")) : query(hcpCollRef, where("account_id", "==", account_id), orderBy("registered"))

    if (_.isFunction(window.healthcareProfessionalsListener)) {
        window.healthcareProfessionalsListener()
        delete window.healthcareProfessionalsListener
    }

    window.healthcareProfessionalsListener = onSnapshot(q, querySnapshot => {
        batch(() => {
            querySnapshot.docChanges().forEach(change => {
                const item = {id: change.doc.id, ...change.doc.data()}

                if (_.has(item, "registered")) {
                    item.registered = item.registered.toMillis()
                }

                if (_.has(item, "online.last_updated")) {
                    item.online.last_updated = item.online.last_updated.toMillis()
                }

                switch (change.type) {
                    case 'added':
                        dispatch(
                            addItem(item)
                        )
                        break
                    case 'modified':
                        dispatch(
                            updateItem(item)
                        )
                        break
                    case 'removed':
                        dispatch(
                            deleteItem(item)
                        )
                        break
                    default:
                        break
                }
            })
        })
    }, error => {
        dispatch(
            addError(error.message)
        )
    })
}

