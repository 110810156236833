import HealthcareProfessionals from '../../ui/HealthcareProfessionals'
import {connect} from 'react-redux'
import {deleteItem, setItem} from '../../../actions/healthcare_professionals'
import {addError} from '../../../actions/error'
import {getOptions} from "./selectors"
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        options: getOptions(state),
        docs: state.healthcare_professionals.docs,
        fetching: state.healthcare_professionals.fetching,
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({
        deleteItem(doc) {
            dispatch(
                deleteItem(doc)
            )
        },
        setItem(doc) {
            dispatch(
                setItem(doc)
            )
        },
        addError(error) {
            dispatch(
                addError(error)
            )
        }
    })

export default connect(mapStateToProps, mapDispatchToProps)(HealthcareProfessionals)
