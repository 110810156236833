import React, {useReducer, useState} from 'react'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import QuickSearch from '../QuickSearch'
import EntryHeader from '../EntryHeader'
import Form from './Form'
import '../../../assets/scss/SuperAdmins/index.scss'
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import NoData from "../Styled/NoData"
import VersionNumber from "../Styled/VersionNumber"
import Tabs from "../Styled/Tabs"
import _ from "lodash"
import {logEvent} from "firebase/analytics"


const CMSAdmins = ({setItem, deleteItem, addError, docs, fetching, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Admins"
    })

    const [query, setQuery] = useState("")
    const [checked, toggle] = useReducer(
        (checked, docID) => {
            const isChecked = _.get(checked, docID, false)

            return {
                ...checked,
                [docID]: !isChecked
            }
        },
        {}
    )

    const filtered = query.length > 0 ? docs.filter(doc => JSON.stringify(doc).toLowerCase().includes(query.toLowerCase())) : docs

    return (
        <main className="app admins">

            {fetching &&
                <ProgressBarIndeterminate/>
            }

            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Admins"/>
                <QuickSearch
                    handleQuickSearch={q => setQuery(q)}
                    entries={filtered.length}
                    linkTo="/admins/add" linkTitle="ADD ADMIN"
                />

                <section className="content">

                    <Tabs>
                        {filtered.map(doc =>
                            <li key={doc.id}>
                                <input
                                    type="checkbox"
                                    id={doc.id}
                                    checked={_.get(checked, `${doc.id}`, false)}
                                    onChange={() => toggle(doc.id)}
                                />
                                <label className="tab-label" htmlFor={doc.id}>{doc.admin_id}</label>
                                <div className="tab-content">
                                    {_.get(checked, `${doc.id}`, false) &&
                                        <Form
                                            key={doc.id}
                                            {...doc}
                                            handleDelete={deleteItem}
                                            handleUpdate={setItem}
                                            addError={addError}
                                        />
                                    }
                                </div>
                            </li>
                        )}
                    </Tabs>

                    {filtered.length === 0 &&
                        <NoData>No data</NoData>
                    }

                </section>
            </article>
            <VersionNumber/>
        </main>
    )

}


export default CMSAdmins