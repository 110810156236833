import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import '../../assets/scss/QuickSearch.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import ResetButton from "./Styled/ResetButton"


const QuickSearch = ({handleQuickSearch, entries, linkTo, linkTitle}) => {

    const handleReset = e => {
        e.preventDefault()

        setQueryValue("")
        handleQuickSearch("")
    }

    const handleQuickSearchChange = e => {
        setQueryValue(e.target.value)
        handleQuickSearch(e.target.value.toLowerCase())
    }

    const [queryValue, setQueryValue] = useState('')

    return (
        <section className="quick-search">
            <form onSubmit={e => e.preventDefault()}>
                <div className="quick-search-container">
                    <input
                        type="text"
                        placeholder="Quick search..."
                        value={queryValue}
                        onChange={handleQuickSearchChange}
                    />
                    {queryValue.length > 0 &&
                        <ResetButton onClick={handleReset} type="reset"/>
                    }
                </div>

                {entries > 0 &&
                    <p>{entries} entries</p>
                }
            </form>

            <Link
                data-cy="link-add"
                to={linkTo}
                className="btn"
                data-tooltip-id="quick-search-tooltip"
                data-tooltip-content="Click to add record">
                {linkTitle}
            </Link>
            <Tooltip
                id="quick-search-tooltip"
                float={true}
            />
        </section>
    )
}

export default QuickSearch