import ACTION from '../../constants/actionType'


export const addError = message =>
    ({
        type: ACTION.ERROR_NEW_DOC,
        payload: message
    })

export const clearError = index =>
    ({
        type: ACTION.ERROR_DELETE_DOC,
        payload: index
    })