const AppointmentColors = {
    hexColor(str) {
        if (str.toLowerCase().includes("nutricia")) {
            return "#8f67a4";
        } else if (str.toLowerCase().includes("nhs")) {
            return "#508ecc";
        } else {
            return "#bababa";
        }
    }
}


export default AppointmentColors