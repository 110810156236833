import React from 'react'
import {Link, useMatch, useResolvedPath} from "react-router-dom"


const NavItem = ({children, to, ...props}) => {
    const resolved = useResolvedPath(to)
    const match = useMatch({path: resolved.pathname, caseSensitive: true, end: false})

    return (
        <li className={match ? 'active' : null}>
            <Link
                to={to}
                {...props}
            >
                {children}
            </Link>
        </li>
    )
}

export default NavItem
