import styled from "styled-components"
import PrimaryButton from "./PrimaryButton"
import theme from "./theme"


const AddAppointmentButton = styled(PrimaryButton)`
  width: 100%;
  margin-top: 64px;
`

AddAppointmentButton.defaultProps = {
    theme: theme
}

export default AddAppointmentButton