import Accounts from '../../ui/Accounts'
import {connect} from 'react-redux'
import {deleteItem, setItem} from '../../../actions/accounts'
import {addError} from '../../../actions/error'
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        docs: state.accounts.docs,
        fetching: state.accounts.fetching,
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({
        deleteItem(doc) {
            dispatch(
                deleteItem(doc)
            )
        },
        setItem(doc) {
            dispatch(
                setItem(doc)
            )
        },
        addError(error) {
            dispatch(
                addError(error)
            )
        }
    })


export default connect(mapStateToProps, mapDispatchToProps)(Accounts)