import React from 'react'
import Header from '../container/Header'
import ShowErrors from './Errors/ShowErrors'
import EntryHeader from './EntryHeader'
import '../../assets/scss/Lost.scss'
import {logEvent} from "firebase/analytics"


const Lost = ({analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Lost"
    })

    return (
        <main className="app lost">
            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Oops! That page can’t be found."/>
                <p>It looks like nothing was found at this location. Maybe try one of the links above or a search?</p>
            </article>
        </main>
    )
}


export default Lost