import styled from 'styled-components'
import theme from "./theme"


const TrashButton = styled.input`
	width: 19px;
	height: 24px;
	margin: 0 7px 0 9px;
	justify-self: end;
	align-self: center;
`

TrashButton.defaultProps = {
    theme: theme
}

export default TrashButton