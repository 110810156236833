import {createSelector} from 'reselect'
import _ from 'lodash'
import moment from "moment-timezone"
import Timezone from "../../../constants/timezone"


export const optionsCalc = docs => [{label: "All accounts", value: "all"}, ...docs.map(i => ({
    label: i.display_name,
    value: i.account_id
}))]


export const datesCalc = docs => _.chain(docs)
    .map(i => i.date_formatted)
    .uniq()
    .value()


export const patientsCalc = docs => _.chain(docs)
    .groupBy(i => i.date_formatted)
    .map((value, date) => _.chain(value)
        .filter(i => i.role === "patient")
        .values()
        .map(j => j.rating)
        .mean()
        .round(1)
        .value()
    )
    .value()

export const millisToMomentSelector = millis => moment.tz(millis, Timezone)
const getStartDateUnix = state => state.feedback.start_date
const getEndDateUnix = state => state.feedback.end_date

export const hcpCalc = docs => _.chain(docs)
    .groupBy(i => i.date_formatted)
    .map((value, date) => _.chain(value)
        .filter(i => i.role === "hcp")
        .values()
        .map(j => j.rating)
        .mean()
        .round(1)
        .value()
    )
    .value()


export const dataCalc = docs => [["date", "comment", "rating", "role"], ...docs.map(i => ([
    i.date_formatted_for_table ?? "", i.comment.trim() ?? "", i.rating ?? "", i.role ?? ""
]))]

export const getDocs = state => state.feedback.docs
export const getAccountDocs = state => state.accounts.docs
export const getOptions = createSelector(getAccountDocs, optionsCalc)
export const getDates = createSelector(getDocs, datesCalc)
export const getPatients = createSelector(getDocs, patientsCalc)
export const getHCP = createSelector(getDocs, hcpCalc)
export const getData = createSelector(getDocs, dataCalc)
export const getStartDate = createSelector(getStartDateUnix, millisToMomentSelector)
export const getEndDate = createSelector(getEndDateUnix, millisToMomentSelector)
