import Header from '../ui/Header'
import {connect} from 'react-redux'
import {signOutUser} from '../../actions/header'


const mapStateToProps = (state, props) =>
    ({})

const mapDispatchToProps = dispatch =>
    ({
        signOutUser() {
            dispatch(
                signOutUser()
            )
        }
    })

export default connect(mapStateToProps, mapDispatchToProps)(Header)