import React from 'react'
import '../../assets/scss/EntryHeader.scss'


const EntryHeader = props => (
    <header className="entry-header">
        <h1 className="entry-title" data-cy="entry-header">{props.title}</h1>
    </header>
)

export default EntryHeader
