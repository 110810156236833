import styled from "styled-components"
import theme from "./theme"


const EventWrapperWrapper = styled.span`
  display: grid;
  grid-template-columns: auto 13px;
  grid-gap: 4px;
  cursor: grab;

  > span {
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }


  &:active {
    cursor: grabbing;
  }
`

EventWrapperWrapper.defaultProps = {
    theme: theme
}


export default EventWrapperWrapper