import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {deleteDoc, doc, increment, setDoc} from "firebase/firestore"
import {addError} from "../error"
import getKeywords from "../../util/getKeywords"


const isFetching = fetching =>
    ({
        type: ACTION.SUPER_ADMIN_FETCHING_DOCS,
        payload: fetching
    })


export const deleteItem = itemID => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        await deleteDoc(doc(db, COLLECTION.SUPER_ADMINS, itemID))

        const superAdminCounterRef = doc(db, COLLECTION.COUNTERS, COLLECTION.SUPER_ADMINS)

        await setDoc(superAdminCounterRef, {total: increment(-1)}, {merge: true})
    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}

export const setItem = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {

        const temp = {
            password: item.password,
            first_name: item.first_name,
            last_name: item.last_name,
            email: item.email,
            keywords: getKeywords(item.admin_id, item.first_name, item.last_name, item.email)
        }

        await setDoc(doc(db, COLLECTION.SUPER_ADMINS, item.id), temp, {merge: true})

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}