import React from 'react'
import {Navigate} from "react-router-dom"
import ShowErrors from '../../container/ShowErrors'
import logo from '../../../assets/svg/logo.svg'
import '../../../assets/scss/Login/Login.scss'
import ProgressBarInDeterminate from "../ProgressBar/ProgressBarIndeterminate"
import {useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import FormField from "../Styled/FormField"
import InputField from "../Styled/InputField"
import LoginButton from "../Styled/LoginButton"
import ResetButton from "../Styled/ResetButton"
import VersionNumber from "../Styled/VersionNumber"
import {logEvent} from "firebase/analytics"


const Login = ({fetching, user, signInWithAdminIDAndPassword, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Login"
    })

    const {register, handleSubmit, setValue, watch, formState: {errors}} = useForm({
        mode: 'onSubmit',
        defaultValues: {
            admin_id: "",
            password: ""
        }
    })

    const [admin_id, password] = watch(["admin_id", "password"])

    const onSubmit = ({admin_id, password}) => signInWithAdminIDAndPassword(admin_id, password)

    if (user != null) {
        return <Navigate to="/data"/>
    } else {
        return (
            <>
                {fetching &&
                    <ProgressBarInDeterminate/>
                }
                <ShowErrors/>
                <section id="login">
                    <div className="inner-content">
                        <header className="App-header">
                            <img
                                data-cy="logo"
                                src={logo}
                                className="logo"
                                alt="logo"
                            />
                        </header>

                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            onKeyDown={(e) => {
                                e.key === 'Enter' && handleSubmit(onSubmit)
                            }}
                        >
                            <FormField>
                                <InputField
                                    data-cy="username-input-field"
                                    type="text"
                                    isValid={!!errors.admin_id}
                                    placeholder="Please enter your admin ID..."
                                    autoComplete="username"
                                    tabIndex="10"
                                    {...register(
                                        'admin_id',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {errors.admin_id && <InvalidFeedback>The `Admin ID` field is required</InvalidFeedback>}
                                {admin_id.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('admin_id', '')}
                                        type="reset"
                                    />
                                }
                            </FormField>
                            <FormField>
                                <InputField
                                    type="password"
                                    data-cy="password-input-field"
                                    isValid={!!errors.password}
                                    placeholder="Please enter your password..."
                                    autoComplete="current-password"
                                    tabIndex="20"
                                    {...register(
                                        'password',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {errors.password && <InvalidFeedback>The `Password` field is required</InvalidFeedback>}
                                {password.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('password', '')}
                                        type="reset"
                                    />
                                }
                            </FormField>

                            <LoginButton
                                type="submit"
                                data-cy="login-button"
                                tabIndex="30"
                                className={(fetching ? 'sync' : '')}>
                                LOG IN
                            </LoginButton>
                        </form>

                    </div>
                </section>
                <VersionNumber/>
            </>
        )
    }
}


export default Login
