import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import trashIcon from '../../../assets/svg/trash.svg'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/SuperAdmins/Add.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import ResetButton from "../Styled/ResetButton"
import {useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import isEmailValid from "../../../util/isEmailValid"
import TrashButton from "../Styled/TrashButton"
import PrimaryButton from "../Styled/PrimaryButton"
import VersionNumber from "../Styled/VersionNumber"
import {logEvent} from "firebase/analytics"


const Add = ({addItem, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Add Admin"
    })

    const navigate = useNavigate()

    const {register, handleSubmit, setValue, watch, formState: {errors}, reset} = useForm({
        mode: 'onSubmit',
        defaultValues: {
            admin_id: "",
            password: "",
            first_name: "",
            last_name: "",
            email: ""
        }
    })

    const onSubmit = data => {

        addItem(data)

        navigate('/admins')
    }

    const [admin_id, password, first_name, last_name, email] = watch(["admin_id", "password", "first_name", "last_name", "email"])

    return (
        <main className="app add-admin">
            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Add Admin"/>

                <section className="content">

                    <h3>New Admin</h3>
                    <Link
                        to="/admins"
                        className="close"
                        data-tooltip-id="add-admin-tooltip"
                        data-tooltip-content="Click to close">
                        <img
                            src={close}
                            alt="Close button"
                        />
                    </Link>

                    <form
                        className="add-admin-form"
                        onSubmit={handleSubmit(onSubmit)}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                        }}
                    >

                        <div className="admin_id">
                            <Label
                                data-tooltip-id="add-admin-tooltip"
                                data-tooltip-content="Admin's ID">
                                Admin ID
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.admin_id}
                                    type="text"
                                    placeholder="Please enter an admin ID..."
                                    {...register(
                                        'admin_id',
                                        {
                                            required: true,
                                            validate: {
                                                lowerCase: value => value.toLowerCase() === value,
                                                noSpaces: value => value.replace(" ", "") === value,
                                                longerThanThreeLetters: value => value.length > 2
                                            }
                                        })
                                    }
                                />
                                {admin_id.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('admin_id', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.admin_id && errors.admin_id.type === 'required' &&
                                <InvalidFeedback>The `Admin ID` field is required</InvalidFeedback>
                            }
                            {errors.admin_id && errors.admin_id.type === 'lowerCase' &&
                                <InvalidFeedback>The `Admin ID` needs to be in lowercase</InvalidFeedback>
                            }
                            {errors.admin_id && errors.admin_id.type === 'noSpaces' &&
                                <InvalidFeedback>The `Admin ID` cannot have spaces</InvalidFeedback>
                            }
                            {errors.admin_id && errors.admin_id.type === 'longerThanThreeLetters' &&
                                <InvalidFeedback>The `Admin ID` must be at least 3 characters</InvalidFeedback>
                            }
                        </div>

                        <div className="password">
                            <Label
                                data-tooltip-id="add-admin-tooltip"
                                data-tooltip-content="Admin's Password">
                                Password
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    autoComplete="off"
                                    isValid={!!errors.password}
                                    type="text"
                                    placeholder="Please enter a password..."
                                    {...register(
                                        'password',
                                        {
                                            required: true,
                                            validate: {
                                                lowerCase: value => value.toLowerCase() === value,
                                                noSpaces: value => value.replace(" ", "") === value,
                                                longerThanThreeLetters: value => value.length > 2
                                            }
                                        })
                                    }
                                />
                                {password.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('password', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.password && errors.password.type === 'required' &&
                                <InvalidFeedback>The `Password` field is required</InvalidFeedback>
                            }
                            {errors.password && errors.password.type === 'lowerCase' &&
                                <InvalidFeedback>The password needs to be in lowercase</InvalidFeedback>
                            }
                            {errors.password && errors.password.type === 'noSpaces' &&
                                <InvalidFeedback>The password cannot have spaces</InvalidFeedback>
                            }
                            {errors.password && errors.password.type === 'longerThanThreeLetters' &&
                                <InvalidFeedback>The password must be at least 3 characters</InvalidFeedback>
                            }
                        </div>

                        <div className="first_name">
                            <Label
                                data-tooltip-id="add-admin-tooltip"
                                data-tooltip-content="Admin's First Name">
                                First name
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.first_name}
                                    type="text"
                                    placeholder="Please enter a first name..."
                                    {...register(
                                        'first_name',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {first_name.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('first_name', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.first_name && <InvalidFeedback>The `First Name` field is required</InvalidFeedback>}

                        </div>

                        <div className="last_name">
                            <Label
                                data-tooltip-id="add-admin-tooltip"
                                data-tooltip-content="Admin's Last Name">
                                Last name
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.last_name}
                                    type="text"
                                    placeholder="Please enter a last name..."
                                    {...register(
                                        'last_name',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {last_name.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('last_name', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.last_name && <InvalidFeedback>The `Last Name` field is required</InvalidFeedback>}
                        </div>

                        <div className="email">
                            <Label
                                data-tooltip-id="add-admin-tooltip"
                                data-tooltip-content="Admin's Email">
                                Email
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.email}
                                    type="text"
                                    placeholder="Please enter an email"
                                    {...register(
                                        'email',
                                        {
                                            required: true,
                                            validate: {
                                                isValidEmail: value => isEmailValid(value)
                                            }
                                        })
                                    }
                                />
                                {email.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('email', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.email && errors.email.type === 'required' &&
                                <InvalidFeedback>The `Email` field is required</InvalidFeedback>
                            }
                            {errors.email && errors.email.type === 'isValidEmail' &&
                                <InvalidFeedback>Please enter a valid email</InvalidFeedback>
                            }

                        </div>

                        <TrashButton
                            className="reset"
                            onClick={() => reset()}
                            type="image"
                            alt=""
                            src={trashIcon}
                            data-tooltip-id="add-admin-tooltip"
                            data-tooltip-content="Click to reset record"
                        />

                        <PrimaryButton
                            data-tooltip-id="add-admin-tooltip"
                            data-tooltip-content="Click to add record"
                            className="add btn"
                            type="submit"
                        >ADD ADMIN</PrimaryButton>

                        <Tooltip
                            id="add-admin-tooltip"
                            float={true}
                        />

                    </form>

                </section>

            </article>
            <VersionNumber/>
        </main>
    )
}


export default Add