import ACTION from '../../../constants/actionType'

const initialState = []

const errors = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.ERROR_NEW_DOC:
            const newDoc = {
                message: action.payload,
                isNewError: true
            }

            const oldState = state.map(i => {
                i.isNewError = false
                return i
            })

            return [
                ...oldState,
                newDoc
            ]
        case ACTION.ERROR_DELETE_DOC:
            return state
                .map(i => {
                    i.isNewError = false
                    return i
                })
                .filter((error, i) => i !== action.payload)
        default:
            return state
    }
}


export default errors