import React from 'react'


const ChevronLeft = ({enabled}) => (
    <svg width="16" height="30" viewBox="0 0 16 30">
        <path
            fill={enabled ? "#D3007D" : "#CCCCCC"}
            d="M15.3 29.76l.47-.47c.31-.32.31-.83 0-1.14L2.85 15 15.77 1.85c.31-.31.31-.82 0-1.14L15.3.24a.773.773 0 0 0-1.12 0L.23 14.43c-.31.31-.31.83 0 1.14l13.95 14.19c.31.32.81.32 1.12 0zm0 0"
        />
    </svg>
)

export default ChevronLeft
