import styled from 'styled-components'
import theme from "./theme"


const BlueButton = styled.button`
    background-color: #D3007D;
    color: white;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    padding: 10px 25px;
    border-radius: 5px;
    border: none;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.3s ease-in;
    
    &:hover{
      background-color: #a0005f;
    }
`

BlueButton.defaultProps = {
    theme: theme
}

export default BlueButton