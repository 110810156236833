import styled from 'styled-components'
import theme from "./theme"


const VersionNumberWrapper = styled.aside`
  position: fixed;
  bottom: 8px;
  right: 8px;
  font-size: 15px;
  color: ${props => props.theme.brandSecondary};
  z-index: 9;
`

VersionNumberWrapper.defaultProps = {
    theme: theme
}

const VersionNumber = () => (<VersionNumberWrapper>{process.env.REACT_APP_VERSION}</VersionNumberWrapper>)


export default VersionNumber