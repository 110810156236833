import styled from "styled-components"
import theme from "./theme"


const H2Wrapper = styled.h2`
  text-align: center;
  margin: 0 0 32px 0;
  font-size: 26px;
  color: #A7A8AA;
`

H2Wrapper.defaultProps = {
    theme: theme
}

export default H2Wrapper