import React, {useEffect} from 'react'
import {Navigate} from "react-router-dom"


const PrivateRoute = ({user, dataListeners, children}) => {

    useEffect(() => {
        if (!!user) {
            dataListeners()
        }
    }, [user])

    return user !== null ? children : <Navigate to={"/login"}/>
}

export default PrivateRoute