import React, {useState} from 'react'
import {Calendar, momentLocalizer, Views} from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import "react-big-calendar/lib/css/react-big-calendar.css"
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import '../../../assets/scss/Appointments/index.scss'
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import ShowErrors from "../../container/ShowErrors"
import Header from "../../container/Header"
import EntryHeader from "../EntryHeader"
import DeleteEvent from "./DeleteEvent"
import SlideDrawer from "./SlideDrawer"
import SearchWrapper from '../Styled/SearchWrapper'
import ResetButton from "../Styled/ResetButton"
import BlueButton from "../Styled/BlueButton"
import EventWrapperWrapper from '../Styled/EventWrapperWrapper'
import moment from 'moment-timezone'
import Select from "react-select"
import Timezone from "../../../constants/timezone"
import colourStyles from "../../../constants/colourStyles"
import VersionNumber from "../Styled/VersionNumber"
import {DebounceInput} from 'react-debounce-input'
import {logEvent} from "firebase/analytics"


const localizer = momentLocalizer(moment)

const MonthEventInfo = ({event}) => {
    return (
        <EventWrapperWrapper>
			<span><strong>{moment.tz(event.start, Timezone).format("h:mma")}</strong>&nbsp;
                {event.health_care_professional.hcp_id}-{event.patient.patient_id}</span>
            <DeleteEvent/>
        </EventWrapperWrapper>
    )
}

const WeekEventInfo = ({event}) => {
    return (
        <EventWrapperWrapper>
            <span>{event.patient.patient_id}-{event.health_care_professional.hcp_id}</span>
            <DeleteEvent/>
        </EventWrapperWrapper>
    )
}

const DayEventInfo = ({event}) => {
    return (
        <EventWrapperWrapper>
            <span>Patient ID:&nbsp;{event.patient.patient_id} and HCP ID:&nbsp;{event.health_care_professional.hcp_id}</span>
            <DeleteEvent/>
        </EventWrapperWrapper>
    )
}

const DraggableCalendar = withDragAndDrop(Calendar)

const Appointments = ({
                          options = [],
                          account_id = "all",
                          fetching = false,
                          docs = [],
                          queryString = "",
                          appointmentListener,
                          cancelAppointment,
                          setAppointment,
                          rescheduleAppointment,
                          addQuery,
                          date,
                          addDate,
                          addAccountID,
                          analytics
                      }) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Appointments"
    })

    const [showValue, setShowValue] = useState(false)

    const copyToClipboard = str => {
        navigator.clipboard.writeText(str).then(
            () => {
            },
            () => {
            }
        )
    }

    const events = docs.map(i => {
        const startDate = moment.tz(i.date, Timezone)

        i.title = `Appointment between Patient ID:${i.patient.patient_id} and HCP ID: ${i.health_care_professional.hcp_id}`
        i.start = startDate.toDate()
        i.end = startDate.clone().add(30, 'minutes').toDate()

        return i
    })

    const reschedule = obj => {
        const morning = moment.tz(Timezone).startOf('day').valueOf()
        const apptStart = moment.tz(obj.start.getTime(), Timezone).valueOf()

        if (morning <= apptStart) {
            rescheduleAppointment(obj.event, obj.start)
        }
    }

    const handleSearchForm = e => {
        e.preventDefault()
        appointmentListener()
    }

    return (
        <main className="app appointments">

            {fetching &&
                <ProgressBarIndeterminate/>
            }

            <ShowErrors/>
            <Header/>
            <article className="inner-content">

                <EntryHeader title="Appointments"/>

                <SearchWrapper>
                    <form onSubmit={handleSearchForm}>
                        <div id="query-filter">
                            <DebounceInput
                                type="text"
                                value={queryString}
                                placeholder="Quick search..."
                                debounceTimeout={300}
                                onChange={e => {
                                    addQuery(e.target.value)
                                    appointmentListener()
                                }}
                            />
                            {queryString.length > 0 &&
                                <ResetButton
                                    onClick={() => {
                                        addQuery("")
                                        appointmentListener()
                                    }}
                                    type="reset"
                                />
                            }
                        </div>
                        <div id="account-id-filter">
                            <Select
                                name="filter"
                                className="react-select-field"
                                placeholder="Please select account..."
                                value={options.find(account => account.value === account_id)}
                                onChange={selected => {
                                    addAccountID(selected.value)
                                    appointmentListener()
                                }}
                                options={options}
                                styles={colourStyles}
                            />
                        </div>

                        {events.length > 0 &&
                            <p>{events.length} entries</p>
                        }
                    </form>

                    <BlueButton onClick={() => setShowValue(!showValue)}>ADD APPOINTMENT</BlueButton>

                </SearchWrapper>


                <section className="content">

                    <DraggableCalendar
                        selectable
                        date={date}
                        events={events}
                        localizer={localizer}
                        defaultView={Views.MONTH}
                        views={[Views.MONTH, Views.WEEK, Views.DAY]}
                        drilldownView={Views.DAY}
                        popup={false}
                        step={15}
                        timeslots={4}
                        resizeable={false}
                        dayLayoutAlgorithm="no-overlap"
                        resizableAccessor={() => false}
                        eventPropGetter={(event, start, end, isSelected) => {
                            const backgroundColor = event.hexColor
                            const style = {
                                backgroundColor: backgroundColor
                            }

                            return {
                                style: style,
                                className: 'video-call-appointment'
                            }
                        }}
                        components={{
                            month: {
                                event: MonthEventInfo
                            },
                            week: {
                                event: WeekEventInfo
                            },
                            day: {
                                event: DayEventInfo
                            }
                        }}
                        style={{height: 900, width: '100%'}}
                        onNavigate={date => {
                            addDate(date)
                            appointmentListener()
                        }}
                        onSelectEvent={(event, {target}) => {
                            copyToClipboard(`Appointment Date: ${moment.tz(event.start, Timezone).format("lll")}\nPatient ID: ${event.patient.patient_id}\nHealthcare Professional ID: ${event.health_care_professional.hcp_id}`)

                            if (target.classList.contains('delete-event')) {
                                cancelAppointment(event)
                            } else {
                                const eventsContainer = target.closest(".rbc-events-container")
                                if (!!eventsContainer) {
                                    [...eventsContainer.querySelectorAll('.rbc-event')]
                                        .forEach(i => i.style.zIndex = 1)
                                    if (target.classList.contains('.rbc-selected')) {
                                        target.style.zIndex = 2
                                    } else {
                                        const rbcSelected = target.closest('.rbc-selected')
                                        if (!!rbcSelected) {
                                            rbcSelected.style.zIndex = 2
                                        }
                                    }
                                }
                            }
                        }}
                        onEventDrop={event => reschedule(event)}
                        messages={{
                            showMore: target => <span
                                role="presentation"> ...{target} more appointment{target > 1 ? 's' : ''}</span>
                        }}
                    />

                </section>

                {showValue &&
                    <SlideDrawer
                        show={!showValue}
                        closeDrawer={() => setShowValue(false)}
                        setAppointment={setAppointment}
                    />
                }

            </article>
            <VersionNumber/>
        </main>
    )
}


export default Appointments



