import React, {useReducer, useState} from 'react'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import Form from './Form'
import '../../../assets/scss/HealthcareProfessionals/index.scss'
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import QuickSearchWithAccountFilter from "../QuickSearchWithAccountFilter"
import NoData from "../Styled/NoData"
import VersionNumber from "../Styled/VersionNumber"
import Tabs from "../Styled/Tabs"
import _ from "lodash"
import {logEvent} from "firebase/analytics"


const HealthcareProfessionals = ({docs, fetching, setItem, deleteItem, options, addError, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Healthcare Professionals"
    })

    const [query, setQuery] = useState("")
    const [accountFilter, setAccountFilter] = useState('all')
    const [checked, toggle] = useReducer(
        (checked, docID) => {
            const isChecked = _.get(checked, docID, false)

            return {
                ...checked,
                [docID]: !isChecked
            }
        },
        {}
    )

    let filtered = docs

    if (accountFilter !== 'all') {
        filtered = filtered.filter(doc => doc.account_id === accountFilter)
    }

    if (query.length > 0) {
        filtered = filtered.filter(doc => JSON.stringify(doc).toLowerCase().includes(query.toLowerCase()))
    }


    return (
        <main className="app healthcare-professionals">
            {fetching &&
                <ProgressBarIndeterminate/>
            }
            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Healthcare Professionals"/>

                <QuickSearchWithAccountFilter
                    query={query}
                    setQuery={setQuery}
                    accountFilter={accountFilter}
                    setAccountFilter={setAccountFilter}
                    options={options}
                    entries={filtered.length}
                    linkTo="/healthcare-professionals/add"
                    linkTitle="ADD HEALTHCARE PROFESSIONAL"
                />

                <section className="content">

                    <Tabs>
                        {filtered.map(doc =>
                            <li key={doc.id}>
                                <input
                                    type="checkbox"
                                    id={doc.id}
                                    checked={_.get(checked, `${doc.id}`, false)}
                                    onChange={() => toggle(doc.id)}
                                />
                                <label className="tab-label" htmlFor={doc.id}>{doc.hcp_id}</label>
                                <div className="tab-content">
                                    {_.get(checked, `${doc.id}`, false) &&
                                        <Form
                                            key={doc.id}
                                            options={options}
                                            {...doc}
                                            handleUpdate={setItem}
                                            handleDelete={deleteItem}
                                            addError={addError}
                                        />
                                    }

                                </div>
                            </li>
                        )}
                    </Tabs>

                    {filtered.length === 0 &&
                        <NoData>No data</NoData>
                    }

                </section>
            </article>
            <VersionNumber/>
        </main>
    )

}

export default HealthcareProfessionals