import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {addDoc, collection, doc, getDocs, increment, query, setDoc, Timestamp, where} from "firebase/firestore"
import {addError} from "../error"
import getKeywords from "../../util/getKeywords"


const isFetching = fetching =>
    ({
        type: ACTION.ACCOUNT_FETCHING_DOCS,
        payload: fetching
    })

export const addItem = ({
                            account_id = "",
                            display_name = ""
                        }) => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {

        const accountsCollRef = collection(db, COLLECTION.ACCOUNTS)
        const q = query(accountsCollRef, where("account_id", "==", account_id))

        const querySnapshot = await getDocs(q)

        if (!querySnapshot.empty) {
            throw new Error(`Account with id: ${account_id} already exists, please use a unique id`)
        }

        const temp = {
            account_id: account_id,
            display_name: display_name,
            registered: Timestamp.now(),
            keywords: getKeywords(account_id, display_name)
        }

        await addDoc(accountsCollRef, temp)

        const accountCounterRef = doc(db, COLLECTION.COUNTERS, COLLECTION.ACCOUNTS)

        await setDoc(accountCounterRef, {total: increment(1)}, {merge: true})

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}