import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {collection, onSnapshot, query, where} from "firebase/firestore"
import {addError} from "../error"
import moment from "moment-timezone"
import Timezone from "../../constants/timezone"
import _ from "lodash"
import {workerThread} from "../../util/workerThread"
import {batch} from "react-redux"


const isFetching = fetching =>
    ({
        type: C.FEEDBACK_FETCHING_DOCS,
        payload: fetching
    })

const addItem = item =>
    ({
        type: C.FEEDBACK_NEW_DOC,
        payload: item
    })

const deleteItem = item =>
    ({
        type: C.FEEDBACK_DELETE_DOC,
        payload: item
    })

const updateItem = item =>
    ({
        type: C.FEEDBACK_UPDATE_DOC,
        payload: item
    })


const deleteAllItems = () =>
    ({
        type: C.FEEDBACK_CLEAR_DOCS
    })

export const setAccountID = (item = 'all') =>
    ({
        type: C.FEEDBACK_ACCOUNT_ID,
        payload: item
    })

export const setStartDate = (item = moment.tz(Timezone).subtract(6, 'd').valueOf()) =>
    ({
        type: C.FEEDBACK_START_DATE,
        payload: item
    })

export const setEndDate = (item = moment.tz(Timezone).valueOf()) =>
    ({
        type: C.FEEDBACK_END_DATE,
        payload: item
    })

export const feedbackListener = () => async (dispatch, getState) => {

    const {feedback: {docs = [], start_date, end_date, account_id}} = getState()

    const startDate = moment.tz(start_date, Timezone).startOf('day').toDate()
    const endDate = moment.tz(end_date, Timezone).endOf('day').toDate()

    dispatch(
        isFetching(true)
    )

    let q = query(
        collection(db, COLLECTION.FEEDBACK),
        where('date', '>=', startDate),
        where('date', '<=', endDate)
    )

    if (account_id !== 'all') {
        q = query(q, where('account_id', '==', account_id))
    }

    if (_.isFunction(window.feedbackDocListener)) {
        window.feedbackDocListener()
        delete window.feedbackDocListener
    }

    window.feedbackDocListener = onSnapshot(q, querySnapshot => {
        if (querySnapshot.empty) {
            dispatch(
                deleteAllItems()
            )
            dispatch(
                isFetching(false)
            )
        } else {
            const queryDocs = querySnapshot.docs.map(i => ({id: i.id, ...i.data()}))

            docs.filter(i => !queryDocs.find(j => i.id === j.id))
                .forEach(i => dispatch(deleteItem(i)))

            const testAccounts = [
                "hxiFdSG5ICEjIUNYGgwy-patient",
                "nMrmreg6VhO0nCeKPC3p-patient",
                "Yp5eHgFXJOpuJJjuLSkh-patient",
                "h3OEFEjhy88D3T3Zmo9k-patient",
                "v1XJ5luR4J6kUhJ7uUmh-patient",
                "NXQwvcvUiOn6JUTJtNW9-hcp",
                "8z50lyLTxbyEtO7ocCNo-hcp",
                "iYuubK45REeUwoOQn6SC-hcp",
                "O14WW5SdDGBuuKY9Xgm7-hcp",
                "Dt2s1IyTA2BzmOZu31no-hcp"
            ]
            const noOfChanges = querySnapshot.docChanges().length - 1


            querySnapshot.docChanges().forEach((change, index) => {

                workerThread(() => {
                    const item = {id: change.doc.id, ...change.doc.data()}
                    item.date = item.date.toMillis()
                    item.date_formatted = moment.tz(item.date, Timezone).format("DD/MM/YY")
                    item.date_formatted_for_table = moment.tz(item.date, Timezone).format("DD/MM/YY h:mm a")

                    if (_.has(item, "commenter_uid") && !testAccounts.includes(item.commenter_uid)) {
                        switch (change.type) {
                            case 'added':
                                dispatch(
                                    addItem(item)
                                )
                                break
                            case 'modified':
                                dispatch(
                                    updateItem(item)
                                )
                                break
                            case 'removed':
                                dispatch(
                                    deleteItem(item)
                                )
                                break
                            default:
                                break
                        }
                    }

                    dispatch(
                        isFetching(index !== noOfChanges)
                    )
                })

            })
        }
    }, error => {
        batch(() => {
            dispatch(
                isFetching(false)
            )

            dispatch(
                addError(error.message)
            )
        })
    })
}