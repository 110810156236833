import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {deleteDoc, doc, increment, setDoc} from "firebase/firestore"
import {addError} from "../error"
import getKeywords from "../../util/getKeywords"


const isFetching = fetching =>
    ({
        type: ACTION.ACCOUNT_FETCHING_DOCS,
        payload: fetching
    })


export const deleteItem = itemID => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        await deleteDoc(doc(db, COLLECTION.ACCOUNTS, itemID))

        const accountCounterRef = doc(db, COLLECTION.COUNTERS, COLLECTION.ACCOUNTS)

        await setDoc(accountCounterRef, {total: increment(-1)}, {merge: true})
    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}

export const setItem = ({
                            id = "",
                            account_id = "",
                            display_name = "",
                        }) => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        const temp = {
            account_id: account_id,
            display_name: display_name,
            keywords: getKeywords(account_id, display_name)
        }

        await setDoc(doc(db, COLLECTION.ACCOUNTS, id), temp, {merge: true})
    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}