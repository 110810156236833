import Data from '../../ui/Data'
import {connect} from 'react-redux'
import {setDataProcessingStage, uploadDataDocs} from '../../../actions/data'
import {addError} from '../../../actions/error'
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        fetching: state.data.fetching,
        data_processing_stage: state.data.data_processing_stage,
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({
        uploadDataDocs(inputFile, addRecords) {
            dispatch(
                uploadDataDocs(inputFile, addRecords)
            )
        },
        setDataProcessingStage(stage) {
            dispatch(
                setDataProcessingStage(stage)
            )
        },
        addError(error) {
            dispatch(
                addError(error)
            )
        }

    })

export default connect(mapStateToProps, mapDispatchToProps)(Data)  
