import React from 'react'


const RemoveRecordIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
        <path fill='#fff'
              d='M 19.58,9 L 0.42,9 C 0.19,9 0,9.22 0,9.5 L 0,11.5 C 0,11.78 0.19,12 0.42,12 L 19.58,12 C 19.81,12 20,11.78 20,11.5 L 20,9.5 C 20,9.22 19.81,9 19.58,9 Z M 19.58,9'
        />
    </svg>
)

export default RemoveRecordIcon