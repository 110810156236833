import React from 'react'
import '../../../assets/scss/UsefulInformation/Form.scss'
import trashIcon from '../../../assets/svg/trash.svg'
import Select from 'react-select'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import roleType from '../../../constants/roleType'
import isUrlValid from '../../../util/isUrlValid'
import isPhoneValid from '../../../util/isPhoneValid'
import InputNumber from "rc-input-number"
import 'rc-input-number/assets/index.css'
import ResetButton from "../Styled/ResetButton"
import {Controller, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import colourStyles from "../../../constants/colourStyles"
import PrimaryButton from "../Styled/PrimaryButton"


const Form = props => {

    const {register, handleSubmit, setValue, watch, formState: {errors}, control} = useForm({mode: 'onSubmit'})

    const onSubmit = data => {
        data.id = props.id
        data.account_id = data.account_id.value
        data.group = data.group.value

        props.handleUpdate(data)
    }

    const title = watch("title", props.title)
    const url = watch("url", props.url)


    return (
        <form
            className="edit-useful-information-form"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault()
            }}
        >

            <div className="account_id">
                <Label
                    data-tooltip-id={`update-useful-info-tooltip-${props.id}`}
                    data-tooltip-content="The Account ID for the item">
                    Account ID
                </Label>
                <Controller
                    render={({field: {onChange, value, name, ref}}) => (
                        <Select
                            value={value}
                            name={name}
                            options={props.options}
                            onChange={onChange}
                            className="react-select-field"
                            placeholder="Please select an account..."
                            styles={colourStyles}
                            ref={ref}
                        />
                    )}
                    defaultValue={props.options.find(account => account.value === props.account_id)}
                    control={control}
                    rules={{required: true}}
                    name="account_id"
                />
                {errors.account_id && <InvalidFeedback>The `Account ID` field is required</InvalidFeedback>}
            </div>

            <div className="group">
                <Label
                    data-tooltip-id={`update-useful-info-tooltip-${props.id}`}
                    data-tooltip-content="The group for the item">
                    Group
                </Label>
                <Controller
                    render={({field: {onChange, value, name, ref}}) => (
                        <Select
                            value={value}
                            name={name}
                            options={roleType}
                            onChange={onChange}
                            className="react-select-field"
                            placeholder="Please select a group..."
                            styles={colourStyles}
                            ref={ref}
                        />
                    )}
                    defaultValue={roleType.find(role => role.value === props.group)}
                    control={control}
                    rules={{required: true}}
                    name="group"
                />
            </div>

            <div className="title">
                <Label
                    data-tooltip-id={`update-useful-info-tooltip-${props.id}`}
                    data-tooltip-content="The title for the item">
                    Title
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.title}
                        type="text"
                        placeholder="Please enter a title..."
                        defaultValue={props.title}
                        {...register(
                            'title',
                            {
                                required: true
                            })
                        }
                    />
                    {title.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('title', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.title && <InvalidFeedback>The `Title` field is required</InvalidFeedback>}
            </div>

            <div className="url">
                <Label
                    data-tooltip-id={`update-useful-info-tooltip-${props.id}`}
                    data-tooltip-content="The url or telephone for the item">
                    URL
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.url}
                        type="text"
                        placeholder="Please enter a url or telephone..."
                        defaultValue={props.url}
                        {...register(
                            'url',
                            {
                                required: true,
                                validate: {
                                    isValidUrlOrPhone: value => isUrlValid(value) || isPhoneValid(value)
                                }
                            })
                        }
                    />
                    {url.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('url', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.url && errors.url.type === "required" &&
                    <InvalidFeedback>The `URL` field is required</InvalidFeedback>
                }
                {errors.url && errors.url.type === 'isValidUrlOrPhone' &&
                    <InvalidFeedback>Please enter a valid url</InvalidFeedback>
                }

            </div>

            <div className="index">
                <Label
                    data-tooltip-id={`update-useful-info-tooltip-${props.id}`}
                    data-tooltip-content="The rank order for the item">
                    Order
                </Label>
                <Controller
                    render={({field: {onChange, value, name, ref}}) => (
                        <InputNumber
                            name={name}
                            min={1}
                            value={value}
                            onChange={onChange}
                            ref={ref}
                        />
                    )}
                    defaultValue={props.index}
                    control={control}
                    rules={{required: true}}
                    name="index"
                />
            </div>

            <input
                className="trash"
                onClick={e => {
                    e.preventDefault()
                    props.handleDelete(props.id)
                }}
                type="image"
                alt="delete item"
                src={trashIcon}
                data-tooltip-id={`update-useful-info-tooltip-${props.id}`}
                data-tooltip-content="Click to delete record"
            />

            <PrimaryButton
                className="update btn"
                data-tip={`Click to update record`}
                data-tooltip-id={`update-useful-info-tooltip-${props.id}`}
                data-tooltip-content="Click to update record"
                type="submit"
            >UPDATE</PrimaryButton>

            <Tooltip
                id={`update-useful-info-tooltip-${props.id}`}
                float={true}
            />
        </form>
    )
}


export default Form