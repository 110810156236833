import React, {useEffect, useState} from 'react'
import AsyncSelect from 'react-select/async'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import moment from "moment-timezone"
import SlideDrawerWrapper from '../Styled/SlideDrawerWrapper'
import DrawerContent from '../Styled/DrawerContent'
import DrawerHandel from '../Styled/DrawerHandel'
import Backdrop from '../Styled/Backdrop'
import H2Wrapper from '../Styled/H2Wrapper'
import FormField from '../Styled/FormField'
import AddAppointmentButton from '../Styled/AddAppointmentButton'
import FormFieldWrapper from '../Styled/FormFieldWrapper'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import {db} from '../../../actions'
import {collection, getDocs, limit, orderBy, query, where} from "firebase/firestore"
import COLLECTION from "../../../constants/collections"
import Label from "../Styled/Label"
import colourStyles from "../../../constants/colourStyles"
import Timezone from "../../../constants/timezone"


const SlideDrawer = ({
                         closeDrawer = false,
                         setAppointment
                     }) => {

    const [showValue, setShowValue] = useState(false)
    const [hcpValue, setHcpValue] = useState(null)
    const [patientValue, setPatientValue] = useState(null)
    const [dateValue, setDateValue] = useState(new Date())
    const [accountIDValue, setAccountIDValue] = useState("all")


    useEffect(() => {
        setTimeout(() => setShowValue(true), 200)
    }, [closeDrawer])


    const loadHCP = async (hcp_id) => {
        const collectionRef = collection(db, COLLECTION.HEALTH_CARE_PROFESSIONALS)
        const q = query(
            collectionRef,
            where("keywords", "array-contains", hcp_id.toLowerCase().replace(/\s/g, '')),
            orderBy("registered"),
            limit(10)
        )

        const querySnapshot = await getDocs(q)

        return querySnapshot.empty ? [] : querySnapshot.docs.map(i => ({
            id: i.id,
            label: i.data().hcp_id,
            value: i.data().hcp_id, ...i.data()
        }))
    }

    const loadPatients = async (patient_id) => {
        const collectionRef = collection(db, COLLECTION.PATIENTS)

        const q = query(
            collectionRef,
            where('account_id', 'in', [accountIDValue, "all"]),
            where("keywords", "array-contains", patient_id.toLowerCase().replace(/\s/g, '')),
            orderBy("registered"),
            limit(10)
        )

        const querySnapshot = await getDocs(q)

        return querySnapshot.empty ? [] : querySnapshot.docs.map(i => ({
            id: i.id,
            label: i.data().patient_id,
            value: i.data().patient_id, ...i.data()
        }))
    }


    const close = () => {
        setShowValue(false)
        setTimeout(() => closeDrawer(), 200)
    }


    const addAppointment = async () => {
        if (!!hcpValue && !!patientValue && !!dateValue) {
            await setAppointment(hcpValue, patientValue, dateValue)
            close()
        }
    }


    return (
        <>
            <SlideDrawerWrapper show={showValue} style={{fontSize: "16px"}}>
                <DrawerContent>
                    <H2Wrapper>Add Appointment</H2Wrapper>

                    <FormField>
                        <Label
                            data-tooltip-id="side-drawer-tooltip"
                            data-tooltip-content="The Healthcare Professional ID">
                            Healthcare Professional ID
                        </Label>
                        <AsyncSelect
                            id="hcp_id"
                            key="hcp_id"
                            name="hcp_id"
                            placeholder="Please begin typing for suggestions below"
                            loadOptions={loadHCP}
                            defaultOptions
                            onChange={selected => {
                                setHcpValue(selected)
                                setAccountIDValue(selected.account_id)
                            }}
                            styles={colourStyles}
                        />
                    </FormField>

                    <FormField>
                        <Label
                            data-tooltip-id="side-drawer-tooltip"
                            data-tooltip-content="The Patient ID">
                            Patient ID
                        </Label>
                        <AsyncSelect
                            id="patient_id"
                            key="patient_id"
                            name="patient_id"
                            isDisabled={!hcpValue}
                            placeholder="Please begin typing for suggestions below"
                            loadOptions={loadPatients}
                            defaultOptions
                            onChange={selected => setPatientValue(selected)}
                            styles={colourStyles}
                        />
                    </FormField>

                    <FormFieldWrapper>
                        <Label
                            data-tooltip-id="side-drawer-tooltip"
                            data-tooltip-content="Appointment Date and Time">
                            Appointment Date and Time
                        </Label>

                        <Datetime
                            dateFormat="D/M/YYYY"
                            inputProps={{
                                disabled: !(!!hcpValue && !!patientValue)
                            }}
                            value={moment.tz(dateValue, Timezone)}
                            onChange={date => setDateValue(date)}
                            closeOnSelect={true}
                            isValidDate={(currentDate, selectedDate) => {
                                if (!selectedDate) {
                                    return currentDate.isAfter(moment.tz(Timezone).subtract(1, 'd'))
                                } else {
                                    return selectedDate.isAfter(moment.tz(Timezone).subtract(1, 'd'))
                                        && currentDate.isAfter(moment.tz(Timezone).subtract(1, 'd'))
                                }
                            }}
                        />
                    </FormFieldWrapper>

                    <AddAppointmentButton
                        disabled={!(!!hcpValue && !!patientValue && !!dateValue?.valueOf() && dateValue?.valueOf() >= new Date())}
                        onClick={() => addAppointment()}>
                        ADD APPOINTMENT
                    </AddAppointmentButton>
                    <Tooltip
                        id="side-drawer-tooltip"
                        float={true}
                    />
                </DrawerContent>
                <DrawerHandel onClick={() => close()}/>
            </SlideDrawerWrapper>
            <Backdrop/>
        </>
    )
}

export default SlideDrawer